import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getDashboardRole, State } from '../reducers';
import { CustomFieldsService } from "app/services/custom-fields.service";
import { catchError, concatMap, map, switchMap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { CustomFieldActions, CustomFieldEntrySuccessActions } from "../actions/customfields";
import { CustomFieldEntity } from "app/models/custom-fields";

@Injectable()
export class CustomFieldsEffects {
 
  createCustomFieldSchema$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.createCustomFieldSchema),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ schemaToAdd, tempId }, role]) => this.customFieldsService.createCustomFieldSchema(role.companyId as string, schemaToAdd).pipe(
      map(addedSchema => CustomFieldActions.createCustomFieldSchemaSuccess({ addedSchema, tempId })),
      catchError(error => of(CustomFieldActions.createCustomFieldSchemaFailure({ tempId, error }))),
    ))
  ));

  deleteCustomFieldSchema$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.deleteCustomFieldSchema),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([action, role]) => this.customFieldsService.deleteCustomFieldSchema(role.companyId as string, action.schemaToDelete).pipe(
      map(success => CustomFieldActions.deleteCustomFieldSchemaSuccess({ success, index: action.index })),
      catchError(error => of(CustomFieldActions.deleteCustomFieldSchemaFailure({ error }))),
    ))
  ));

  getCustomFieldSchemas$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.getAllCustomFieldSchemas),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([, role]) => this.customFieldsService.getCustomFieldSchemas(role.companyId as string).pipe(
      map(schemas => CustomFieldActions.getAllCustomFieldSchemasSuccess({ schemas })),
      catchError(error => of(CustomFieldActions.getAllCustomFieldSchemasFailure({ error }))),
    ))
  ));

  getCustomFieldSchemasByEntity$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.getCustomFieldSchemasByEntity),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ entity }, role]) => this.customFieldsService.getCustomFieldSchemas(role.companyId as string, entity).pipe(
      map(schemas => CustomFieldActions.getCustomFieldSchemasByEntitySuccess({ schemas })),
      catchError(error => of(CustomFieldActions.getCustomFieldSchemasByEntityFailure({ error }))),
    ))
  ));

  updateCustomFieldSchema$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.updateCustomFieldSchema),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ schemaToUpdate, originalSchema, index }, role]) => this.customFieldsService.updateCustomFieldSchema(role.companyId as string, schemaToUpdate).pipe(
      map(updatedSchema => CustomFieldActions.updateCustomFieldSchemaSuccess({ updatedSchema, index })),
      catchError(error => of(CustomFieldActions.updateCustomFieldSchemaFailure({ originalSchema, index, error }))),
    ))
  ));

  createCustomFieldEntry$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.createCustomFieldEntry),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ entryToAdd, originalEntry }, role]) => this.customFieldsService.createCustomFieldEntry(role.companyId as string, entryToAdd).pipe(
      map(addedEntry => CustomFieldActions.createCustomFieldEntrySuccess({ addedEntry, customFieldEntity: entryToAdd.customFieldEntity })),
      catchError(error => of(CustomFieldActions.createCustomFieldEntryFailure({ error, originalEntry }))),
    ))
  ));

  deleteCustomFieldEntry$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.deleteCustomFieldEntry),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ entryToDelete, originalEntry }, role]) => this.customFieldsService.deleteCustomFieldEntry(role.companyId as string, entryToDelete).pipe(
      map(success => CustomFieldActions.deleteCustomFieldEntrySuccess({ success, deletedEntry: entryToDelete, originalEntry, customFieldEntity: entryToDelete.customFieldEntity })),
      catchError(error => of(CustomFieldActions.deleteCustomFieldEntryFailure({ error, originalEntry }))),
    )),
  ));

  getCustomFieldEntriesByEntityAndEntityIds$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIds),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([action, role]) => this.customFieldsService.getCustomFieldEntries(role.companyId as string, action.entity, action.entityIds).pipe(
      map(entries => CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIdsSuccess({entries})),
      catchError(error => of(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIdsFailure(error))),
    ))
  ));

  updateCustomFieldEntry$ = createEffect(() => this.actions$.pipe(
    ofType(CustomFieldActions.updateCustomFieldEntry),
    withLatestFrom(this.store.select(getDashboardRole)),
    concatMap(([{ originalEntry, entryToUpdate }, role]) => this.customFieldsService.updateCustomFieldEntry(role.companyId as string, entryToUpdate).pipe(
      map(updatedEntry => CustomFieldActions.updateCustomFieldEntrySuccess({ updatedEntry, customFieldEntity: entryToUpdate.customFieldEntity })),
      catchError(error => of(CustomFieldActions.updateCustomFieldEntryFailure({ originalEntry, error })))
    ))
  ));
  
  refreshCustomEntriesAfterCreateEditDelete = createEffect(() => this.actions$.pipe(
    ofType(
      CustomFieldActions.createCustomFieldEntrySuccess,
      CustomFieldActions.updateCustomFieldEntrySuccess,
      CustomFieldActions.deleteCustomFieldEntrySuccess,
    ),
    switchMap((action) => {
      const { entity, entityIds } = this._getEntityAndEntityIdsFromAction(action);

      if (entity && entityIds.length > 0) {
        return [
          CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIds({entity, entityIds})
        ];
      }

      return [];
    })
  ));

  private _getEntityAndEntityIdsFromAction(action: CustomFieldEntrySuccessActions): { entity: CustomFieldEntity, entityIds: string[] } {
    const entity = action.customFieldEntity;

    let entityId: string | undefined;

    if ('addedEntry' in action) {
      entityId = action.addedEntry.entityId;
    } else if ('updatedEntry' in action) {
      entityId = action.updatedEntry.entityId;
    } else if ('originalEntry' in action) {
      entityId = action.originalEntry.entityId;
    }
  
    if (!entity || !entityId) {
      throw new Error('Missing entity or entityId in refreshCustomFieldEntries$ effect');
    }
  
    return { entity, entityIds: [ entityId ] };
  }

  constructor(
    private actions$: Actions,
    private customFieldsService: CustomFieldsService,
    private store: Store<State>
  ) {}
}