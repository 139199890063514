import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ActionTypes, SeedDatabaseSuccessAction } from '../actions/seed';
import { State, getDashboardSelectedDate, getDashboardRole } from '../reducers';
import { withLatestFrom, switchMap, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SeedService } from 'app/services/seed.service';
import {
  LoadTimeEntriesFromServerSuccessAction
} from '../actions/timer';

@Injectable()
export class SeedEffects {
  seedDatabase: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SEEDDATABASE),
      switchMap(() => this.seedService.seedCurrentDates()),
      withLatestFrom(
        this.store.select(getDashboardSelectedDate),
        this.store.select(getDashboardRole)
      ),
      mergeMap(([entries, date, role]) => {

        return [
          new SeedDatabaseSuccessAction(),
          new LoadTimeEntriesFromServerSuccessAction(entries)
        ]
      })
    ));

  constructor(
    private action$: Actions,
    private store: Store<State>,
    private seedService: SeedService
  ) { }
}
