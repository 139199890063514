import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { State, getDashboardRole, getDashboardSelectedDate, getDashboardSelectedDateRange } from 'app/redux/reducers';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { Client } from 'app/models/Client';
import { Project } from 'app/models/project';
import { TimeEntry } from 'app/models/timeentry';
import { TimerService } from './timer.service';


@Injectable()
export class SeedService {
  private baseUrl = `${environment.server}/seed`;

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private timerService: TimerService
  ) { }

  public seedClients(numClients: number) {
    return this.store.select(getDashboardRole).pipe(
      switchMap(role => {
        return this.http.post<Client[]>(`${this.baseUrl}/CreateClients?companyId=${role.companyId}&numClients=${numClients}`, {});
      })
    )
  }

  public seedProjects(clientId: string, numProjects: number) {
    return this.http.post<Project[]>(`${this.baseUrl}/CreateProjects?clientId=${clientId}&numProjects=${numProjects}`, {});
  }

  public seedTimeEntries(projectId: string, numHoursPerDay: number) {
    return this.store.select(getDashboardSelectedDate).pipe(
      switchMap(date => {
        const [startDate, endDate] = this.timerService.getWeekRange(date);
        return this.http.post<TimeEntry[]>(`${this.baseUrl}/CreateTimeEntries?projectId=${projectId}&numHoursPerDay=${numHoursPerDay}&startDate=${startDate}&endDate=${endDate}`, {})
      })
    );
  }

  public seedCurrentDates() {
    return this.store.select(getDashboardSelectedDateRange).pipe(
      switchMap(dates => {
        return this.http.post<TimeEntry[]>(`${this.baseUrl}/BlindSeed?startDate=${new Date(dates[0]).toISOString()}&endDate=${new Date(dates[1]).toISOString()}`, null);
      })
    )
  }
}
