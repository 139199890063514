
import { debounceTime, switchMap, catchError, map, mergeMap, withLatestFrom, delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of, Observable, from } from 'rxjs';
import { ClientService } from '../../services/client.service';
import {
  ActionTypes,
  LoadAllClientsAction,
  LoadClientsFromLocalDbSuccessAction,
  LoadClientsFromServerFailedAction,
  LoadClientsFromServerSuccessfulAction,
  OpenClientAddDialogAction,
  OpenClientAddDialogFailedAction,
  OpenClientAddDialogSuccessfulAction,
  OpenClientCardFailedAction,
  OpenClientCardSuccessAction,
  OpenClientDialogCardAction,
  SaveClientsToDbFailedAction,
  SaveClientsToDbSuccessfulAction,
  SubmitClientAction,
  SubmitClientFailedAction,
  SubmitClientSuccessfulAction,
  UpdateClientPageAction,
  UpdateClientsAction,
  UpdateClientsFailureAction,
  UpdateClientsSuccessAction,
  EditClientAction,
  EditClientSuccessAction,
  EditClientFailureAction,
  OpenDeleteClientDialogAction,
  OpenDeleteClientDialogSuccessfulAction,
  OpenDeleteClientDialogFailedAction,
  LoadEnterpriseClientsSuccessAction,
  LoadEnterpriseClientsFailureAction,
  OpenEnterpriseClientDialogCardAction,
  OpenEnterpriseClientDialogCardSuccessAction,
  OpenEnterpriseClientDialogCardFailedAction,
  OpenEnterpriseClientAddDialogAction,
  OpenEnterpriseClientAddDialogSuccessAction,
  OpenEnterpriseClientAddDialogFailedAction,
  LoadClientExpensesAction,
  LoadClientExpensesFailureAction,
  LoadClientExpensesSuccessAction,
  UploadTimeSheetTemplateAction,
  UploadTimeSheetTemplateSuccessAction,
  UploadTimeSheetTemplateFailureAction,
  ResetTimeSheetTemplateStatusAction,
  ResetTimeSheetExampleStatusAction,
  DownloadCompanyTimeSheetTemplateAction,
  DownloadCompanyTimeSheetTemplateSuccessAction,
  DownloadCompanyTimeSheetTemplateFailureAction,
  DownloadClientTimeSheetTemplateAction,
  DownloadClientTimeSheetTemplateSuccessAction,
  DownloadClientTimeSheetTemplateFailureAction,
  DownloadTimeSheetExampleAction,
  DownloadTimeSheetExampleSuccessAction,
  DownloadTimeSheetExampleFailureAction
} from '../actions/client';
import { Client, ClientError } from '../../models/Client';
import { ApiErrorResponse } from 'app/models/apierror';
import { AddAlertAction } from '../actions/alerts';
import { GoToDashboardAction } from '../actions/dashboard';
import { State, getDashboardRole } from '../reducers';
import { SnackbarService } from 'app/services/snackbar.service';
import { HttpResponse } from '@angular/common/http';
import { saveFileFromBlobResponse } from 'app/shared/helpers';
import { CustomFieldActions } from '../actions/customfields';

@Injectable()
export class ClientEffects {
  loadAllClients$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<LoadAllClientsAction>(ActionTypes.LOADALLCLIENTS),
      map(action =>
        new LoadClientsFromLocalDbSuccessAction([])
      )));

  loadClientsFromServer$ = createEffect(() => this.action$
    .pipe(
      // trace('loadClientsFromServer:Effect'),
      ofType(ActionTypes.LOADCLIENTSFROMDBSUCCESSFUL),
      switchMap(action =>
        this.clientService.GetAllClientsFromServer().pipe(
          map(response => {
            return new LoadClientsFromServerSuccessfulAction(response)
          }),
          catchError(err => of(new LoadClientsFromServerFailedAction(err))))
      )));

  checkForAlerts$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOADCLIENTSFROMSERVERSUCCESSFUL),
      switchMap((action: LoadClientsFromServerSuccessfulAction) =>
        this.clientService.checkForAlerts(action.payload).pipe(
          mergeMap(alerts => alerts.map(alert => new AddAlertAction(alert)))
        )
    )));

  LoadEnterpriseClientsFromServer$ = createEffect(() => this.action$
    .pipe(
      // trace('LoadEnterpriseClientsFromServer:Effect'),
      ofType(ActionTypes.LOADENTERPRISECLIENTS),
      switchMap(action => this.clientService.GetEnterpriseClientsFromServer().pipe(
        map(response => new LoadEnterpriseClientsSuccessAction(response)),
        catchError(err => of(new LoadEnterpriseClientsFailureAction(err))))
      )));

  editClient$ = createEffect(() => this.action$
    .pipe(
      ofType<EditClientAction>(ActionTypes.EDITCLIENT),
      switchMap(action => {
        return this.clientService
          .EditClient(action.payload).pipe(
            map(() => new EditClientSuccessAction(action.payload)),
            catchError((err: ApiErrorResponse) =>
              of(new EditClientFailureAction(new ClientError({ error: err, client: action.payload })))
            ));
      })));

  createCustomFieldEntries$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITCLIENTSUCCESSFUL),
      mergeMap((action: SubmitClientSuccessfulAction) => {
        const clientId = action.payload.clientID;
        const customFields = action.customFields || [];

        return from(customFields).pipe(
          map(({ entryToAdd, originalEntry }) => 
            CustomFieldActions.createCustomFieldEntry({ 
              entryToAdd: { ...entryToAdd, entityId: clientId },
              originalEntry 
            })
          )
        );
      })
    )
  );

  closeMobileDialog$: Observable<void> = createEffect(() => this.action$
    .pipe(
      ofType<EditClientSuccessAction>(
        ActionTypes.EDITCLIENTSUCCESSFUL
      ),
      map((action) => this.clientService.checkForAlerts([action.payload])),
      switchMap(() => this.clientService.hideClientCard())
    ), { dispatch: false });

  editClientFinished$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<EditClientSuccessAction | EditClientFailureAction>(
        ActionTypes.EDITCLIENTSUCCESSFUL,
        ActionTypes.EDITCLIENTFAILED
      ),
      debounceTime(3000),
      map((action: EditClientSuccessAction | EditClientFailureAction) => new UpdateClientPageAction(action.payload))));

  saveEditedClientToDb$ = createEffect(() => this.action$
    .pipe(
      ofType<EditClientSuccessAction>(ActionTypes.EDITCLIENTSUCCESSFUL),
      switchMap(action => this.clientService.UpsertClientsToLocalDb(action.payload).pipe(
        map(res => new SaveClientsToDbSuccessfulAction(new Client(res))),
        catchError(err => of(new SaveClientsToDbFailedAction(err))))
      )));

  updateClientsBilledRates$ = createEffect(() => this.action$
    .pipe(
      ofType<UpdateClientsAction>(ActionTypes.UPDATECLIENTS),
      switchMap(action => {
        return this.clientService
          .UpdateClientsBilledRates(action.payload).pipe(
            map(res => new UpdateClientsSuccessAction(res)),
            catchError(err => of(new UpdateClientsFailureAction(err))));
      })));

  // saveUpdatedClientsToDb$ = createEffect(() => this.action$
  //   .pipe(
  //     ofType<UpdateClientsSuccessAction>(ActionTypes.UPDATECLIENTSSUCCESSFUL),
  //     switchMap(action =>
  //       this.clientService
  //         .UpsertClientsToLocalDb(action.payload).pipe(
  //           map((res: Client[]) => {
  //             return new SaveClientsToDbSuccessfulAction(res.map(e => new Client(e)));
  //           }),
  //           catchError(err => of(new SaveClientsToDbFailedAction(err))))
  //     )));

  // saveClientsToDb$ = createEffect(() => this.action$
  //   .pipe(
  //     ofType<LoadClientsFromServerSuccessfulAction>(ActionTypes.LOADCLIENTSFROMSERVERSUCCESSFUL),
  //     switchMap(action => {
  //       if (action.payload) {
  //         return this.clientService
  //           .UpsertClientsToLocalDb(action.payload).pipe(
  //             map((res: Client[]) => {
  //               return new SaveClientsToDbSuccessfulAction(res.map(e => new Client(e)));
  //             }));
  //       }
  //       return of(new SaveClientsToDbFailedAction('Clients were null, or something.'));
  //     })));

  // saveClientToDb$ = createEffect(() => this.action$
  //   .pipe(
  //     ofType<SubmitClientSuccessfulAction>(ActionTypes.SUBMITCLIENTSUCCESSFUL),
  //     switchMap(action => {
  //       if (action.payload) {
  //         return this.clientService
  //           .UpsertClientsToLocalDb(action.payload).pipe(
  //             map((res: Client) => {
  //               return new SaveClientsToDbSuccessfulAction(new Client(res));
  //             }));
  //       }
  //       return of(new SaveClientsToDbFailedAction('Clients were null, or something.'));
  //     })));

  clientBulkRatesUpdatedSuccessfully$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.UPDATECLIENTSSUCCESSFUL),
      map(action => new GoToDashboardAction())
    ));

  OpenDeleteClientDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENDELETECLIENTDIALOG),
      switchMap((action: OpenDeleteClientDialogAction) => {
        return this.clientService.showDeleteClientDialog(action.payload).pipe(
          map(res => new OpenDeleteClientDialogSuccessfulAction(action.payload)),
          catchError(err => of(new OpenDeleteClientDialogFailedAction(err))));
      })));

  OpenClientAddDialogFromTimer$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENCLIENTADDDIALOG),
      switchMap((action: OpenClientAddDialogAction) =>
        this.clientService
          .createNewClientDialog().pipe(
            map(() => new OpenClientAddDialogSuccessfulAction()),
            catchError(err => of(new OpenClientAddDialogFailedAction(err))))
      )));

  OpenEnterpriseClientAdd$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENENTERPRISECLIENTADDDIALOG),
      switchMap((action: OpenEnterpriseClientAddDialogAction) =>
        this.clientService
          .createNewEnterpriseClientDialog().pipe(
            map(() => new OpenEnterpriseClientAddDialogSuccessAction()),
            catchError(err => of(new OpenEnterpriseClientAddDialogFailedAction(err)))
          )
      )));
      
  SaveClientToServer$ = createEffect(() => this.action$
    .pipe(ofType(ActionTypes.SUBMITCLIENT),
      switchMap((action: SubmitClientAction) =>
        this.clientService
          .SaveClientToServer(action.payload).pipe(
            map(res => new SubmitClientSuccessfulAction(res, action.payload.customFields ?? [])),
            catchError(err => of(new SubmitClientFailedAction(err))))
      )));

  CloseClientAddDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITCLIENTSUCCESSFUL),
      switchMap((action: SubmitClientSuccessfulAction) => this.clientService.closeAddClientDialog())
    ), { dispatch: false });

  OpenClientCardAction$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENCLIENTCARD),
      switchMap((action: OpenClientDialogCardAction) =>
        this.clientService
          .showClientCard().pipe(
            map(() => new OpenClientCardSuccessAction()),
            catchError(err => of(new OpenClientCardFailedAction(err))))
      )));

  OpenEnterpriseClientCardAction$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENENTERPRISECLIENTCARD),
      switchMap((action: OpenEnterpriseClientDialogCardAction) => {
        return this.clientService
          .showEnterpriseClientCard().pipe(
            map(() => new OpenEnterpriseClientDialogCardSuccessAction()),
            catchError(err => of(new OpenEnterpriseClientDialogCardFailedAction(err))))
      })));

  LoadClientExpensesAction$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOADCLIENTEXPENSES),
      withLatestFrom(this.store.select(getDashboardRole)),
      switchMap(([action, currentRole]) =>
        this.clientService
          .getClientExpenses((action as LoadClientExpensesAction).payload, currentRole.companyId).pipe(
            map(res => new LoadClientExpensesSuccessAction(res)),
            catchError(err => of(new LoadClientExpensesFailureAction(err)))
          )
      )));

  downloadCompanyTimeSheetTemplateAction$ = createEffect(() => this.action$.pipe(
    ofType(DownloadCompanyTimeSheetTemplateAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, currentRole]) => {
      return this.clientService.downloadCompanyTimeSheetTemplate(currentRole.companyId.toString()).pipe(
        map((res: HttpResponse<Blob>) => {
          saveFileFromBlobResponse(res);
          return DownloadCompanyTimeSheetTemplateSuccessAction();
        }),
        catchError((error) => {
          this.snackbarService.displayServerError(`Failed to download the time sheet template`);
          return of(DownloadCompanyTimeSheetTemplateFailureAction({error}));
        })
      );
    })
  ));

  downloadClientTimeSheetTemplateAction$ = createEffect(() => this.action$.pipe(
    ofType(DownloadClientTimeSheetTemplateAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, currentRole]) => {
      return this.clientService.downloadClientTimeSheetTemplate(action.clientId, currentRole.companyId.toString()).pipe(
        map((res: HttpResponse<Blob>) => {
          saveFileFromBlobResponse(res);

          return DownloadClientTimeSheetTemplateSuccessAction();
        }),
        catchError(() => of(DownloadCompanyTimeSheetTemplateAction()))
      );
    })
  ));

  downloadTimeSheetExampleAction$ = createEffect(() => this.action$.pipe(
    ofType(DownloadTimeSheetExampleAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, currentRole]) => {
      return this.clientService.downloadTimeSheetExample(action.clientId, currentRole.companyId.toString()).pipe(
        map((res: HttpResponse<Blob>) => {
          saveFileFromBlobResponse(res);
          return DownloadTimeSheetExampleSuccessAction();
        }),
        catchError(() => of(DownloadTimeSheetExampleAction({clientId: action.clientId})))
      );
    })
  ));

  uploadTimeSheetTemplateAction$ = createEffect(() => this.action$.pipe(
    ofType(UploadTimeSheetTemplateAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, currentRole]) => {
      return this.clientService.uploadTimeSheetTemplate(action.file, action.clientId, currentRole.companyId.toString()).pipe(
        map(() => UploadTimeSheetTemplateSuccessAction()),
        catchError(error => {
          this.snackbarService.displayServerError(`Failed to upload the time sheet template`);
          return of(UploadTimeSheetTemplateFailureAction({error}));
        })
      );
    })
  ));

  resetTimeSheetTemplateStatusAction$ = createEffect(() => this.action$.pipe(
    ofType(
      DownloadCompanyTimeSheetTemplateSuccessAction,
      DownloadCompanyTimeSheetTemplateFailureAction,
      DownloadClientTimeSheetTemplateSuccessAction,
      DownloadClientTimeSheetTemplateFailureAction,
      UploadTimeSheetTemplateSuccessAction,
      UploadTimeSheetTemplateFailureAction
    ),
    delay(500),
    map(() => ResetTimeSheetTemplateStatusAction())
  ));

  resetTimeSheetExampleStatusAction$ = createEffect(() => this.action$.pipe(
    ofType(
      DownloadTimeSheetExampleSuccessAction,
      DownloadTimeSheetExampleFailureAction
    ),
    delay(500),
    map(() => ResetTimeSheetExampleStatusAction())
  ));

  constructor(
    private action$: Actions,
    private clientService: ClientService,
    private store: Store<State>,
    private snackbarService: SnackbarService,
  ) { }
}
