import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { State } from 'app/redux/reducers';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { TimeEntry } from 'app/models/timeentry';
import { EditTimeEntriesAction } from 'app/redux/actions/timer';
import { Status } from 'app/shared/enums';

@Component({
  standalone: true,
  selector: 'app-delete-time-entries',
  templateUrl: './delete-time-entries.component.html',
  styleUrls: ['./delete-time-entries.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class DeleteTimeEntriesComponent {

  constructor(
    private store: Store<State>,
    private dialog: MatDialogRef<DeleteTimeEntriesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { timeEntries: TimeEntry[] }
  ) { }

  public closeDialog() {
    this.dialog.close();
  }

  public deleteEntries() {
    this.store.dispatch(new EditTimeEntriesAction(this.data.timeEntries.map(e => {
      return new TimeEntry(Object.assign({}, e, {
        status: Status.Deleted
      }));
    })));
    this.dialog.close();
  }
}
