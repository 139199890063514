
import { Observable, of as observableOf, empty } from 'rxjs';

import { catchError, switchMap, map, debounceTime, mergeMap } from 'rxjs/operators';
import {
  ActionTypes,
  HarvestLoginAction,
  HarvestLoginSuccessAction,
  HarvestLoginFailureAction,
  TogglDialogSuccessAction,
  TogglDialogFailAction,
  GetAuthStatusAction,
  GetAuthStatusSuccessAction,
  GetAuthStatusFailureAction,
  LoadClientsFromProviderAction,
  LoadClientsFromProviderSuccessAction,
  LoadClientsFromProviderFailureAction,
  ResetApproverProcessAction,
  TogglOpenDialogAction,
  ApproverApplicationLoginSuccessAction,
  ApproverApplicationLoginFailureAction,
  ApproverApplicationLoginAction,
  ApproverApplicationLoginCompleteAction
} from '../actions/approver';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ExternalInvoiceService } from '../../services/external-invoice.service';
import { OAuthIdent } from '../../models/OAuthIdent';
import { ApplicationTypes } from '../../shared/enums';

@Injectable()
export class ApproverEffects {

  getAuthStatus$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType<GetAuthStatusAction>(ActionTypes.GETAUTHSTATUS),
      // trace('getAuthStatus:Effect'),
      switchMap(action => {
        return this.externalService.getAuthStatus().pipe(
          map((res: string[]) => new GetAuthStatusSuccessAction(res)),
          catchError(error => observableOf(new GetAuthStatusFailureAction(error)))
        )
      })));

  harvestlogin$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      // trace('harvestlogin:Effect'),
      ofType<HarvestLoginAction>(ActionTypes.HARVESTLOGIN),
      switchMap(action => {
        return this.authService.harvestLogin().pipe(
          map(res => new HarvestLoginSuccessAction(res)),
          catchError(error => observableOf(new HarvestLoginFailureAction(error)))
        );
      })
    ));

  harvestloginsuccess$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      // trace('harvestloginsuccess:Effect'),
      ofType(ActionTypes.HARVESTLOGINSUCCESS),
      switchMap((action: HarvestLoginSuccessAction) => {
        const oauthIdent = new OAuthIdent();
        oauthIdent.provider = 'Harvest';
        oauthIdent.redirectPath = 'Clients';
        oauthIdent.oauth_token = action.payload;
        return this.externalService.submitAccessTokenToServer(oauthIdent).pipe(
          map(res => new LoadClientsFromProviderAction(ApplicationTypes.Harvest)),
          catchError(error => observableOf(new HarvestLoginFailureAction(error)))
        );
      })
    ));

  harvestfailure$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.HARVESTLOGINFAILURE),
      map(res => new ResetApproverProcessAction())
    ));

  togglopen$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.TOGGLOPENDIALOG),
      switchMap((action: TogglOpenDialogAction) => {
        return this.authService.openTogglLogin(action.payload)
          .pipe(
            map(res => new TogglDialogSuccessAction(res)),
            catchError(error => observableOf(new TogglDialogFailAction(error)))
          );
      })
    ));

  approverApplicationlogin$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.APPROVERAPPLICATIONLOGIN),
      switchMap((action: ApproverApplicationLoginAction) => {
        return this.externalService.passwordLogin(action.payload).pipe(
          mergeMap(results => {
            return [
              new ApproverApplicationLoginSuccessAction(action.payload.provider),
              new LoadClientsFromProviderAction(ApplicationTypes[action.payload.provider])
            ]
          }),
          catchError(error => observableOf(new ApproverApplicationLoginFailureAction({ provider: action.payload.provider, error })))
        )
      })
    ));

  approverApplicationLoginsuccess$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.APPROVERAPPLICATIONLOGINSUCCESS),
      debounceTime(1500),
      map(res => new ApproverApplicationLoginCompleteAction({ result: res, closeDialog: true }))
    ));

  approverApplicationLoginFailure$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.APPROVERAPPLICATIONLOGINFAILURE),
      debounceTime(2500),
      map(res => new ApproverApplicationLoginCompleteAction({ result: res, closeDialog: false }))
    ));

  approverApplicationComplete$: Observable<Action> = createEffect(() =>
    this.action$
    .pipe(
      ofType(ActionTypes.APPROVERAPPLICATIONLOGINCOMPLETE),
      switchMap((action: ApproverApplicationLoginCompleteAction) => {
        return this.authService.closeTogglDialog(action.payload.closeDialog);
      })
    ), { dispatch: false });

  loadClientsFromProvider$: Observable<Action> = createEffect(() =>
    this.action$
    .pipe(
      ofType(ActionTypes.LOADCLIENTSFROMPROVIDER),
      switchMap((action: LoadClientsFromProviderAction) => {
        return this.externalService.loadClientsFromProvider(action.payload).pipe(
          map(res => new LoadClientsFromProviderSuccessAction({ provider: action.payload, clients: res })),
          catchError(error => observableOf(new LoadClientsFromProviderFailureAction(error)))
        );
      })
    ));

  showClientPopup$: Observable<any> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.LOADCLIENTSFROMPROVIDERSUCCESS),
      switchMap((action: LoadClientsFromProviderSuccessAction) => {
        return this.externalService.showClientChoiceDialog(action.payload.provider, action.payload.clients)
      })
    ));

  constructor(private action$: Actions,
    private authService: AuthService,
    private externalService: ExternalInvoiceService,
    private _router: Router) {
  }
}
