import { catchError, delay, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  ForgotPasswordAction,
  PasswordConfirmationSuccessAction,
  PasswordConfirmationErrorAction,
  ActionTypes,
  ResetPasswordConfirmationAction
} from '../actions/forgotpassword';

import { PasswordService } from '../../services/password.service';


@Injectable()
export class ForgotPasswordEffects {
  public forgotpassword$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ForgotPasswordAction),
      switchMap((action) => {
        return this.passwordService.forgotPassword(action.payload).pipe(
          map(() => PasswordConfirmationSuccessAction()),
          catchError(err => of(PasswordConfirmationErrorAction({payload: err}))));
      })
    )
  );

  public resetPasswordConfirmation$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.PASSWORDCONFIRMATIONERROR),
      delay(2500),
      map(() => ResetPasswordConfirmationAction())
    )
  );

  constructor(
    private action$: Actions,
    private passwordService: PasswordService
  ) { }
}
