import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActionTypes,
  AddNotificationAction,
  GetNotificationsAction,
  GetNotificationsFailureAction,
  GetNotificationsSuccessAction,
  UpdateNotificationAction,
  UpdateNotificationsFailureAction,
  UpdateNotificationsSuccessAction
} from '../actions/notifications';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'app/services/notification.service';
import { of } from 'rxjs';
import { Notification } from 'app/models/notification';
import { NotificationType } from 'app/shared/enums';
import { GetUserSettingsAction } from '../actions/settings';

@Injectable()
export class NotificationEffects {

  getNotifications$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GetNotificationsAction>(ActionTypes.GETNOTIFICATIONS),
      switchMap(() => this.notificationService.getNotifications()
        .pipe(
          map(n => new GetNotificationsSuccessAction(n.map(o => new Notification(o)))),
          catchError(err => of(new GetNotificationsFailureAction(err)))
        )
      )
    ));

  updateNotification$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<UpdateNotificationAction>(ActionTypes.UPDATENOTIFICATION),
      switchMap(action => this.notificationService.updateNotification(action.payload)
        .pipe(
          map(n => new UpdateNotificationsSuccessAction(n)),
          catchError(err => of(new UpdateNotificationsFailureAction(err)))
        )
      ),
    ));

  addNotification$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<AddNotificationAction>(ActionTypes.ADDNOTIFICATION),
      map(action => {
        if (action.payload.type === NotificationType.ProviderDisconnect) {
          return new GetUserSettingsAction();
        }
      })
    ));

  constructor(
    private action$: Actions,
    private notificationService: NotificationService
  ) { }
}
