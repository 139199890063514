
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Project } from '../models/project';
import { AddProjectComponent } from '../shared/dialogs/add-project/add-project.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { AddTeamProjectComponent } from 'app/team/projects/add-team-project/add-team-project.component';

@Injectable()
export class ProjectService {
  private baseUrl = `${environment.server}/project`;
  private projectTimerDiagRef: MatDialogRef<AddProjectComponent>;
  private teamProjectDiaglogRef:MatDialogRef<AddTeamProjectComponent>;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog
  ) { }

  public closeAddProjectDialog() {
    if (this.projectTimerDiagRef) {
      this.projectTimerDiagRef.close();
    }
  }

  public closeAddTeamProjectDialog(){
    if(this.teamProjectDiaglogRef){
      this.teamProjectDiaglogRef.close();
    }
  }

  public GetProjectsByClient(clientId: string): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.baseUrl}/GetProjectsByClientId?clientId=${clientId}`);
  }

  public GetAllProjectsFromServer(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.baseUrl}/GetAllProjectsForUser`);
  }

  public GetAllEnterpriseProjectsFromServer(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.baseUrl}/GetAllProjectsForCompany`);
  }

  public MergeProjects(localProjects: Project[], serverProjects: Project[]): Project[] {
    // current behavior: treat the server data as "always right" if it exists
    // i.e., throw out local records and replace them with the newest server records
    if (serverProjects !== null && serverProjects !== undefined) {
      return serverProjects;
    } else {
      return localProjects;
    }
  }

  public AddProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${this.baseUrl}`, JSON.stringify(project));
  }

  public BatchAddProject(projects: Project[]): Observable<Project[]> {
    return this.http.post<Project[]>(`${this.baseUrl}/BatchAdd`, JSON.stringify(projects));
  }

  public BatchUpdateProject(projects: Project[]): Observable<Project[]> {
    return this.http.post<Project[]>(`${this.baseUrl}/BatchUpdate`, JSON.stringify(projects));
  }

  public EditProject(project: Project): Observable<any> {
    return this.http.put<Project>(`${this.baseUrl}/updateproject`, JSON.stringify(project));
  }
  public addProjectDialog(): Observable<any> {
    return new Observable(ob => {
      this.projectTimerDiagRef = this.dialogService.open(AddProjectComponent, {
        panelClass: 'cartwheel-base-dialog',
        disableClose: true
      });
      ob.next('');
    });
  }

  public addTeamProjectDialog(): Observable<any> {
    return new Observable(ob => {
      this.teamProjectDiaglogRef = this.dialogService.open(AddTeamProjectComponent, {
        panelClass: 'cartwheel-base-dialog',
        disableClose: true
      });
      ob.next('');
    });
  }
}
