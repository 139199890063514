<div class="disabled-access-dialog">
  <mat-dialog-content class="mat-typography" >
    <div class="disabled-dialog-content-container">
      <h1 class="access-heading">Oh no! Your trial has ended.</h1>
      <div class="disable-access-content">
        <div>
          Your 30 day trial period has expired. Please review your plan to keep using Cartwheel in the Admin settings. We know you love it!
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
