import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(private updates: SwUpdate,
    private snackbar: MatSnackBar, private appRef: ApplicationRef
  ) {
    this.checkForUpdates();
    // timer(0, 90000).pipe(
    //   tap(() => console.log('emitting a message yo'))
    // ).subscribe(async ()=>{
    //   const updateFound = await this.updates.checkForUpdate();
      
    // });
  }

  checkForUpdates() {
    this.appRef.isStable.pipe(first(isStable => isStable === true)).subscribe(val => {
      this.updates.versionUpdates
        .pipe(filter((event: VersionEvent) => event.type === 'VERSION_READY'))
        .subscribe((event: VersionEvent) => {
          const snack = this.snackbar.open('A new version of Cartwheel is available! Click here to load it', 'Reload');
          // snack._dismissAfter(6000);
          snack.onAction()
            .subscribe(() => window.location.reload());
        });
    });
  }
}
