import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgForage } from 'ngforage';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  private readonly URLSWITHPROPERCONTENTTYPE: string[] = ["/api/File"];
  private needContentTypeChange = (url: string) => this.URLSWITHPROPERCONTENTTYPE.find(e => url.toLocaleLowerCase().includes(e.toLocaleLowerCase()))?.length > 0
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let newReq;
    if ((!req.headers.has('content-type') || !req.headers.has('Content-Type')) && !this.needContentTypeChange(req.url)) {
        newReq = req.clone({
          headers: req.headers.set('content-type', 'application/json')
        });
    }
    if (!req.headers.has('Authorization') && req.url.includes('/api/') && !req.url.includes('/api/Account/ReturnAuth')) {
      return from(this.lStorage.getItem('token')).
        pipe(
          switchMap((token: string) => {
            if (token) {
              if (!newReq) {
                newReq = req.clone();
              }
              newReq = newReq.clone({
                headers: newReq.headers.append('Authorization', `Bearer ${token}`)
              });
              return next.handle(newReq);
            }
            return next.handle(newReq || req);

          }
          ));

    } else {
      return next.handle(newReq || req);
    }

  }
  constructor(private lStorage: NgForage) { }
}
