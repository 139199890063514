import { ApplicationRef, Injectable, } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, combineLatest, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, switchMap, tap, delay, skipWhile, withLatestFrom } from 'rxjs/operators';
import { InvoiceComponent } from '../shared/dialogs/invoice/invoice-client-connection.component';
import { CloseAccountComponent } from '../shared/dialogs/close-account/close-account.component';
import { ReactivateAccountComponent } from '../shared/dialogs/reactivate-account/reactivate-account.component';
import { ExternalInvoicingSystems, UserRole, AccountActive, ScreenSize } from '../shared/enums';
import { environment } from '../../environments/environment';
import { TaxType, UserSettings } from '../models/user-settings';
import { State, getFtuxProvidersState, getDashboardRole, getGSettingsGlobalSettingsModel, getGSettingsPageAccessDisabled } from '../redux/reducers';
import { ProviderState, ExternalInvoice } from '../models/external-invoice';
import { CompanyFeedback } from '../models/companyfeedback';
import { HideReactivateAccountDialogAction } from 'app/redux/actions/settings';
import { DisableAccessComponent } from 'app/shared/dialogs/disable-access/disable-access.component';
import { ResponsiveService } from './responsive.service';
import { SettingHistoryItem, SettingHistoryPagedRequest } from 'app/models/setting-history';
import { PagedResponse } from 'app/models/pagination';
import { DeleteTaxTypesComponent } from 'app/settings/advanced/tax-types/delete-tax-types/delete-tax-types.component';
import { UpdateTaxTypeComponent } from 'app/settings/advanced/tax-types/update-tax-type/update-tax-type.component';

@Injectable()
export class GlobalSettingsService {

  private closeAccountDialogRef: MatDialogRef<CloseAccountComponent>;
  private updateTaxTypeDialogRef: MatDialogRef<UpdateTaxTypeComponent>;
  private showReactivateAccountDialogRef: MatDialogRef<ReactivateAccountComponent>;
  private baseUrl = `${environment.server}/settings`;
  private providers$: Observable<ProviderState>;
  private disableAccessDiagRef: MatDialogRef<DisableAccessComponent>;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog,
    private appRef: ApplicationRef,
    private store: Store<State>,
    private responsiveService: ResponsiveService,
    private router: Router
  ) {
    this.providers$ = this.store.select(getFtuxProvidersState).pipe(skipWhile(p => !p));
  }

  public showInvoiceDialog(payload: ExternalInvoice): Observable<any> {
    return this.providers$.pipe(
      take(1),
      switchMap(providers => new Observable(ob => {
        const config = new MatDialogConfig();
        config.width = '544px';
        config.hasBackdrop = true;
        config.viewContainerRef = this.appRef.components[0].instance.vref;
        config.autoFocus = false;
        config.data = {
          ...payload,
          ...providers[ExternalInvoicingSystems[payload.name]]
        }

        this.dialogService.open(InvoiceComponent, config);
      }))
    );
  }


  public showCloseAccountDialog() {
    const config = {
      panelClass: 'cartwheel-base-dialog',
      maxHeight: '100vh',
      disableClose: true
    };

    return new Observable(ob => {
      this.closeAccountDialogRef = this.dialogService.open(CloseAccountComponent, config);
    });
  }

  public showUpdateTaxTypeDialog(payload: TaxType): Observable<void> {
    const config = new MatDialogConfig();
    config.panelClass = 'cartwheel-base-dialog';
    config.disableClose = true;
    config.data = payload;

    return new Observable(ob => {
      this.updateTaxTypeDialogRef = this.dialogService.open(UpdateTaxTypeComponent, config);
    });
  }

  public showDeleteTaxTypesDialog(payload: TaxType[]): Observable<void> {
    const config = new MatDialogConfig();
    config.width = '544px';
    config.hasBackdrop = true;
    config.data = payload;

    this.dialogService.open(DeleteTaxTypesComponent, config);
    return new Observable();
  }

  public showReactivateAccount(): Observable<boolean> {
    // https://github.com/ngrx/platform/issues/162
    // Grabbing the settings from the reducer will always have the correct value
    // since this method is called after GetUserSettingsSuccess is dispatched
    const settings$ = this.store.select(getGSettingsGlobalSettingsModel).pipe(take(1));
    const role$ = this.store.select(getDashboardRole).pipe(take(1));

    return combineLatest([role$, settings$]).pipe(
      take(1),
      delay(0),
      withLatestFrom(
        this.store.select(getGSettingsPageAccessDisabled),
        this.responsiveService.getScreenSize()
      ),
      switchMap(([[role, settings], pageDisabled, screenSize]) => {
        if (settings.accountActive === AccountActive.active) {
          return of(false);
        }
        if (pageDisabled) {
          return of(true);
        }

        const config = {
          panelClass: 'cartwheel-base-dialog',
          maxHeight: '100vh'
        };

        if (role.userRole === UserRole.User) {
          return new Observable<boolean>(ob => {
            this.disableAccessDiagRef = this.dialogService.open(DisableAccessComponent, {
              ...config,
              height: (screenSize !== ScreenSize.Large) ? '140px' : '',
              minWidth: 'auto'
            });
            this.disableAccessDiagRef.beforeClosed().pipe(
              take(1),
              tap((() => this.store.dispatch(new HideReactivateAccountDialogAction())))
            ).subscribe();
            ob.next(true);
          });
        }

        return new Observable<boolean>(ob => {
          this.showReactivateAccountDialogRef = this.dialogService.open(ReactivateAccountComponent, {
            ...config,
            height: (screenSize !== ScreenSize.Large) ? '450px' : '',
            maxWidth: '100vw',
            minWidth: 'auto',
            data: {}
          });
          this.showReactivateAccountDialogRef.beforeClosed().pipe(
            take(1),
            tap((() => this.store.dispatch(new HideReactivateAccountDialogAction())))
          ).subscribe();
          this.showReactivateAccountDialogRef.componentInstance.close.subscribe(res => {
            this.showReactivateAccountDialogRef.close();
          });
          ob.next(true);
        });
      })
    );
  }

  public hideCloseAccountDialog() {
    return new Observable(ob => {
      if (!this.closeAccountDialogRef) {
        ob.error('There wasn\'t a dialog open');
      }
      this.closeAccountDialogRef.close();
      ob.next('');
    });
  }

  public closeUpdateTaxTypeDialog(): Observable<string> {
    return new Observable(ob => {
      if (!this.updateTaxTypeDialogRef) {
        ob.error('There wasn\'t a dialog open');
      }
      this.updateTaxTypeDialogRef.close();
      ob.next('');
    });
  }

  public submitCloseAccount(feedback: CompanyFeedback): Observable<UserSettings> {
    return this.http.post<UserSettings>(`${this.baseUrl}/closeaccount`, JSON.stringify(feedback));
  }

  public userAccountClosed() {
    return new Observable(ob => {
      if (!this.closeAccountDialogRef) {
        ob.error('There wasn\'t a dialog open');
      }
      this.closeAccountDialogRef.close();
      this.router.navigate(['Login']);
      ob.next('');
    });
  }

  public GetUserSettings(): Observable<UserSettings> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        if (role.userRole === UserRole.User) {
          return this.http.get<UserSettings>(`${this.baseUrl}/usersettings`);
        }
        return this.http.get<UserSettings>(`${this.baseUrl}/teamsettings?companyid=${role.companyId}`);
      })
    )
  }

  public updateGlobalSettings(newSettings: UserSettings): Observable<UserSettings> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        if (role.userRole === UserRole.User) {
          return this.http.put<UserSettings>(`${this.baseUrl}/updatesettings`, JSON.stringify(newSettings));
        }
        return this.http.put<UserSettings>(`${this.baseUrl}/updateteamsettings?companyid=${role.companyId}`, JSON.stringify(newSettings));
      })
    )
  }

  public uploadLogo(file: File): Observable<string> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(
          `${environment.server}/File/UploadCompanyLogo?companyId=${role.companyId}`,
          formData,
          { responseType: 'text' }
        );
      })
    );
  }

  public updateTaxType(payload: TaxType): Observable<TaxType> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        return this.http.put<TaxType>(`${this.baseUrl}/updatetaxtype?companyId=${role.companyId}`, JSON.stringify(payload));
      })
    )
  }

  public deleteTaxTypes(payload: TaxType[]): Observable<TaxType[]> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap((role) => {
        return this.http.post<TaxType[]>(
          `${this.baseUrl}/deletetaxtype?companyId=${role.companyId}`, 
          JSON.stringify(payload.map(x => x.id))
        );
      })
    )
  }

  public getSettingHistory(requestBody?: SettingHistoryPagedRequest, companyId?: string): Observable<PagedResponse<SettingHistoryItem>> {
    const params = new HttpParams({
      fromObject: new SettingHistoryPagedRequest({...requestBody , companyId: companyId}) as Record<string, any>
    });

    return this.http.get<PagedResponse<SettingHistoryItem>>(
      `${environment.server}/auditlog/company`,
      { params }
    );
  }
}
