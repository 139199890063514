
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { RunningTimer, TimeEntry } from '../../models/timeentry';
import { ReportService } from '../../services/report.service';
import { TimerService } from '../../services/timer.service';
import { SelectClientAction } from '../actions/client';
import {
  ActionTypes,
  CheckIfTimerRunningAction,
  CheckIfTimerRunningFailedAction,
  CheckIfTimerRunningSuccessfulAction,
  DeleteTimeEntryAction,
  DeleteTimeEntryFailedAction,
  DeleteTimeEntrySuccessfulAction,
  EditTimeEntriesAction,
  EditTimeEntriesFailedAction,
  EditTimeEntriesSuccessfulAction,
  EndTimerAction,
  EndTimerFailedAction,
  EndTimerSuccessfulAction,
  LoadTimeEntriesFromLocalDBAction,
  LoadTimeEntriesFromLocalDBFailedAction,
  LoadTimeEntriesFromLocalDBSuccessAction,
  LoadTimeEntriesFromServerFailedAction,
  LoadTimeEntriesFromServerSuccessAction,
  OpenAddTimeDialog,
  OpenTimerDialogAction,
  OpenTimerDialogFailedAction,
  OpenTimerDialogSuccessfulAction,
  SaveTimeEntryToLocalDBFailedAction,
  SaveTimeEntryToLocalDBSuccessAction,
  StartTimerAction,
  StartTimerFailedAction,
  StartTimerSuccessfulAction,
  SubmitTimeEntryAction,
  SubmitTimeEntryFailedAction,
  SubmitTimeEntrySuccessfulAction,
  ReportStartTimer,
  ReportEndTimer,
  LoadTimeEntriesFromServerAction,
  LoadCompanyTimeEntriesFromLocalDbAction,
  LoadCompanyTimeEntriesFromServerAction,
  LoadCompanyTimeEntriesFromLocalDbFailureAction,
  TimerStoppedFromServerAction,
} from '../actions/timer';
import { LoadProjectsFromServerAction, SelectProjectAction } from '../actions/project';

@Injectable()
export class TimerEffects {
  checkForRunningTimer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.CHECKTIMERRUNNINGONSERVER),
      switchMap((action: CheckIfTimerRunningAction) => {
        return this.timerService.GetRunningTimer();
      }),
      switchMap((res: RunningTimer) => {
        if (res) {
          return [new CheckIfTimerRunningSuccessfulAction(res), new SelectClientAction(res.client)];
        } else {
          return [new CheckIfTimerRunningSuccessfulAction(null)]
        }
      }),
      catchError(err => of(new CheckIfTimerRunningFailedAction(err)))));

  loadFromLocalDb$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOADTIMEENTRIESFROMLOCALDB),
      mergeMap((action: LoadTimeEntriesFromLocalDBAction) => {
        return this.timerService
          .getLocalDBTimeEntries(action.payload[0], action.payload[1], action.payload[2])
          .pipe(
            switchMap(res => {
              return [
                new LoadTimeEntriesFromLocalDBSuccessAction(res),
                new LoadTimeEntriesFromServerAction(action.payload)
              ];
            }),
            catchError(err => of(new LoadTimeEntriesFromLocalDBFailedAction(err))));
      })));

  loadCompanyTimeEntriesFromLocalDb$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      // trace('loadCompanyTimeEntriesFromLocalDb:Effect'),
      ofType(ActionTypes.LOADCOMPANYTIMEENTRIESFROMLOCALDB),
      mergeMap((action: LoadCompanyTimeEntriesFromLocalDbAction) => {
        return this.timerService
          .getLocalDBTimeEntriesByCompany(action.payload)
          .pipe(
            switchMap(res => {
              return [
                new LoadTimeEntriesFromLocalDBSuccessAction(res),
                new LoadCompanyTimeEntriesFromServerAction(action.payload)
              ];
            }),
            catchError(err => of(new LoadCompanyTimeEntriesFromLocalDbFailureAction(err)))
          );
      })
    ));

  loadComapnyTimeEntriesFromServer$ = createEffect(() => this.action$
    .pipe(
      // trace('loadComapnyTimeEntriesFromServer:Effect'),
      ofType(ActionTypes.LOADCOMPANYTIMEENTRIESFROMSERVER),
      switchMap((action: LoadTimeEntriesFromServerAction) => {
        return this.timerService
          .getServerTimeEntriesByCompany(action.payload)
          .pipe(
            map(response => new LoadTimeEntriesFromServerSuccessAction(response)),
            catchError(err => of(new LoadTimeEntriesFromServerFailedAction(err)))
          )
      })
    ));

  loadFromServer$ = createEffect(() => this.action$
    .pipe(
      // trace('loadTimeEntriesFromServer:Effect'),
      ofType(ActionTypes.LOADTIMEENTRIESFROMSERVER),
      switchMap((action: LoadTimeEntriesFromServerAction) =>
        this.timerService.getServerTimeEntries(action.payload[0], action.payload[1])
          .pipe(
            map(response => new LoadTimeEntriesFromServerSuccessAction(response)),
            catchError(err => of(new LoadTimeEntriesFromServerFailedAction(err)))
          )
    )));

  public openTimerDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENTIMERDIALOG),
      switchMap((action: OpenTimerDialogAction) => {
        return this.timerService
          .showTimerDialog().pipe(
            map(() => new OpenTimerDialogSuccessfulAction(action.payload)),
            catchError(err => of(new OpenTimerDialogFailedAction(err))));
      })));

  public startTimer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.STARTTIMER),
      switchMap((action: StartTimerAction) => {
        const entry = action.payload[0];
        return this.timerService
          .StartTimer(entry).pipe(
            switchMap(response => [new StartTimerSuccessfulAction(response), new ReportStartTimer(response)]),
            catchError(error => of(new StartTimerFailedAction(error))));
      })));

  // @Effect()
  // public reportStartedTimer$: Observable<Action> = this.action$
  //   .ofType(ActionTypes.REPORTSTARTTIMER).pipe(
  //     switchMap((action: ReportStartTimer) =>
  //       this.timerService.reportStartTimer(action.payload)
  //         .pipe(
  //           map(() => new ReportStartTimerSuccessful()),
  //           catchError(err => of(new ReportStartTimerFailed(err)))
  //         ))
  //   )

  // @Effect()
  // public reportEndedTimer$: Observable<Action> = this.action$
  //   .ofType(ActionTypes.REPORENDTIMER).pipe(
  //     switchMap((action: ReportEndTimer) =>
  //       this.timerService.reportEndTimer(action.payload)
  //         .pipe(
  //           map(() => new ReportEndTimerSuccessful()),
  //           catchError(err => of(new ReportEndTimerFailed(err)))
  //         ))
  //   )

  public endTimer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.ENDTIMER),
      switchMap((action: EndTimerAction) => {
        return this.timerService
          .EndTimer(action.payload).pipe(
            switchMap(res => [new EndTimerSuccessfulAction(res), new ReportEndTimer(res)]),
            catchError(err => of(new EndTimerFailedAction(err))));
      })));

  public timerStoppedFromServer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.TIMERSTOPPEDFROMSERVER),
      switchMap((action: TimerStoppedFromServerAction) => {
        return [
          new EndTimerSuccessfulAction(action.payload),
          new LoadProjectsFromServerAction(null),
          new SelectProjectAction(undefined)
        ];
    })));

  public saveTimeEntryLocally$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(
        ActionTypes.ENDTIMERSUCCESS,
        ActionTypes.STARTTIMERSUCCESS,
        ActionTypes.LOADTIMEENTRIESFROMSERVERSUCCESSFUL,
        ActionTypes.SUBMITTIMEENTRYSUCCESSFUL
      ),
      switchMap((action: StartTimerSuccessfulAction) => {
        // fyi, insert expects an array, so proceed accordingly
        let entries = new Array<TimeEntry>();
        entries = action.payload;
        if (action.payload instanceof Array || action.payload) {
          return this.timerService
            .upsertLocalDBTimeEntries(action.payload).pipe(
              map(() => new SaveTimeEntryToLocalDBSuccessAction(entries)),
              catchError(error => of(new SaveTimeEntryToLocalDBFailedAction(error))));
        }

        return of(new SaveTimeEntryToLocalDBFailedAction());
      })));

  public submitTimeEntry$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITTIMEENTRY),
      switchMap((action: SubmitTimeEntryAction) => {
        const newTimeEntry = action.payload;
        return this.timerService
          .addTimeEntry(newTimeEntry).pipe(
            map(response => new SubmitTimeEntrySuccessfulAction(response)),
            catchError(error => of(new SubmitTimeEntryFailedAction(error))));
      })));

  showClientAddDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENADDTIMEDIALOG),
      switchMap((action: OpenAddTimeDialog) => this.reportService.showAddTimeDialog(action.payload))
    ), { dispatch: false });

  closeClientAddDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITTIMEENTRYSUCCESSFUL, ActionTypes.EDITTIMEENTRIESSUCCESSFUL),
      switchMap(() => this.reportService.closeAddTimeDialog())
    ), { dispatch: false });

  public deleteTimeEntry$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.DELETETIMEENTRY),
      switchMap((action: DeleteTimeEntryAction) => {
        const removedTimeEntry: TimeEntry = new TimeEntry(action.payload);
        return this.timerService
          .DeleteTimeEntry(removedTimeEntry).pipe(
            map(() => new DeleteTimeEntrySuccessfulAction(removedTimeEntry)),
            catchError(error => of(new DeleteTimeEntryFailedAction(error))));
      })));

  public deleteTimeEntryLocally$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.DELETETIMEENTRYSUCCESSFUL),
      switchMap((action: DeleteTimeEntrySuccessfulAction) => {
        const removedTimeEntry: TimeEntry = action.payload;
        return this.timerService
          .deleteTimeEntryLocally(removedTimeEntry).pipe(
            map(() => new SaveTimeEntryToLocalDBSuccessAction(removedTimeEntry)),
            catchError(error => of(new SaveTimeEntryToLocalDBFailedAction(error))));
      })));

  public updateTimeEntries$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EDITTIMEENTRIES),
      switchMap((action: EditTimeEntriesAction) => {
        const updatedTimeEntries = action.payload;
        return this.timerService.UpdateTimeEntry(updatedTimeEntries).pipe(
          map(() => new EditTimeEntriesSuccessfulAction(updatedTimeEntries)),
          catchError(error => of(new EditTimeEntriesFailedAction(error))));
      })));

  public updateTimeEntriesLocally$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EDITTIMEENTRIESSUCCESSFUL),
      switchMap((action: EditTimeEntriesSuccessfulAction) => {
        return this.timerService
          .upsertLocalDBTimeEntries(action.payload).pipe(
            map(() => new SaveTimeEntryToLocalDBSuccessAction(action.payload)),
            catchError(error => of(new SaveTimeEntryToLocalDBFailedAction(error))));
      })));

  constructor(
    private action$: Actions,
    private reportService: ReportService,
    private timerService: TimerService
  ) { }
}
