<div class="confirm-dialog">
  <h3 class="header-text">Are you sure you want to delete all the time entries for this time sheet?</h3>
  <div class="dialog-content">
    <cartwheel-button
      class="w-full max-w-150 [&>*]:text-black"
      type="flat-button"
      color="lightGray"
      label="Yes"
      (onClick)="deleteEntries()"
    />
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="secondary"
      label="No"
      (onClick)="closeDialog()"
    />
  </div>
  <cartwheel-icon-button
    class="dialog-close-btn"
    matIconName="clear"
    color="darkGray"
    (onClick)="closeDialog()"
  />
</div>
