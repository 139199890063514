import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { AsyncPipe } from '@angular/common';
import { State, getTimesheetHistory } from '../../../redux/reducers';
import { Client } from '../../../models/Client';
import { EditClientAction } from '../../../redux/actions/client';
import { ReportHistory, ReportHistoryItem } from '../../../models/ReportHistory';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-timesheet-history',
  templateUrl: './timesheet-history.component.html',
  styleUrls: ['./timesheet-history.component.scss'],
  imports: [AsyncPipe, CartwheelIconButtonComponent]
})
export class TimesheetHistoryComponent implements OnInit {

  public client: Client;
  public history: ReportHistory;
  reportHistory$: Observable<ReportHistoryItem[]>;

  constructor(
    private store: Store<State>,
    private dialog: MatDialogRef<TimesheetHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) client: Client
  ) { this.client = client }

  ngOnInit() {
    this.reportHistory$ = this.store.select(getTimesheetHistory);
  }


  public closeDialog() {
    this.dialog.close();
  }
  public deleteClient() {
    this.closeDialog();
    this.store.dispatch(new EditClientAction(this.client));
  }


}
