import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/core';
import {WebSocketLink} from '@apollo/client/link/ws';
import {environment} from '../environments/environment';
import {AuthService} from './services/auth.service';

const uri = environment.graphql.wsEndpoint; // <-- add the URL of the GraphQL server here

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (authService: AuthService) => {
        return {
          cache: new InMemoryCache(),
          link: new WebSocketLink({
            uri,
            options: {
              lazy: true,
              reconnect: true,
              connectionParams: () => ({
                Authorization: `Bearer ${authService.getAuthToken()}`,
              }),
            },
          })
        }
      },
      deps: [AuthService],
    },
  ]
})
export class GraphQLModule {}
