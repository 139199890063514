<div class="dialog-body">
  <div class="dialog-header">
    <span class="dialog-header-text">History</span>
    <cartwheel-icon-button matIconName="clear" class="history-dialog-close-btn" color="darkGray" (onClick)="closeDialog()" />
  </div>
  <div class="dialog-content">
    @for (item of reportHistory$ | async; track 'report-history' + $index) {
      <div class="relative block px-20">
        <div class="history-text">
          <div class="font-medium text-black text-14 leading-1">
            <span [class]="'dot ' + item['change']"></span>
            <span class="ml-6">{{ item.description }}</span>
          </div>
          <div class="text-meta" [class.last]="$index === (reportHistory$ | async).length - 1">
            {{ item.notes }}
          </div>
        </div>
        <div
          class="history-timestamp"
          [class.mt-5]="$index === (reportHistory$ | async).length - 1 && !item['notes']"
        >
          {{ item.timestamp }}
        </div>
      </div>
    }
  </div>
</div>
