import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  State,
  getGSettingsGlobalSettingsModel,
  getGSettingsRetrievedFromServer
} from 'app/redux/reducers';
import { withLatestFrom, take, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UserSettings } from 'app/models/user-settings';
import { ShowReactivateAccountDialogAction } from 'app/redux/actions/settings';
import { AccountActive } from 'app/shared/enums';

@Injectable()
export class ActiveCompanyInterceptorService implements HttpInterceptor {
  currentSettings$: Observable<UserSettings>;
  receivedSettingsFromServer$: Observable<boolean>;
  pageAccessDisabled$: Observable<boolean>;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.currentSettings$.pipe(
      take(1),
      withLatestFrom(this.receivedSettingsFromServer$),
      switchMap(([settings, receivedSettingsFromServer]) => {
        if (!receivedSettingsFromServer) {
          return next.handle(req);
        }

        // Block current page access if current role is not actived
        if (settings.accountActive !== AccountActive.active &&
          !req.url.includes('/api/Account/Subscribe') && !req.url.includes('/api/Account/AddSubscription')) {
          this.store.dispatch(new ShowReactivateAccountDialogAction());
          return throwError({accountInActive: true, message: 'Account is not active, so this network call will not be performed'});
          // return throwError('Account is not active, so this network call will not be performed');
          // return next.handle( new HttpErrorResponse({status: 400, error: 'Account is not active, so this network call will not be performed'}))
        }

        return next.handle(req);
      })
    );
  }

  constructor(private store: Store<State>) {
    this.receivedSettingsFromServer$ = this.store.select(getGSettingsRetrievedFromServer);
    this.currentSettings$ = this.store.select(getGSettingsGlobalSettingsModel);
  }
}
