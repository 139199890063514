import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TeamSelectorComponent } from 'app/shared/dialogs/team-selector/team-selector.component';

@Injectable()
export class DashboardService {

  private teamSelectorDialogRef: MatDialogRef<TeamSelectorComponent>;

  constructor(private dialogService: MatDialog) { }

  public showTeamSelectorDialog() {
    this.teamSelectorDialogRef = this.dialogService.open(TeamSelectorComponent, {
      panelClass: 'team-selector-dialog',
      autoFocus: false
    });
  }
}
