
import { catchError, switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ActionTypes,
  GenerateDetailedExcelFileAction,
  GenerateDetailedExcelFileSuccessAction,
  GenerateDetailedExcelFileFailedAction,
  StartApprovalProcessAction,
  StartApprovalProcessSucceededAction,
  OpenTimesheetHistoryDialogAction,
  OpenTimesheetHistoryDialogSuccessfulAction,
  OpenTimesheetHistoryDialogFailureAction,
  FetchTimesheetHistoryFromServerAction,
  FetchTimesheetHistoryFromServerSuccessAction,
  FetchTimesheetHistoryFromServerFailureAction,
  GenerateOverviewExcelFileAction,
  GenerateOverviewExcelFileSuccessfulAction,
  GenerateOverviewExcelFileFailedAction,
  GenerateDetailedPdfFileAction,
  GenerateDetailedPdfFileSuccessAction,
  GenerateDetailedPdfFileFailedAction,
  GenerateOverviewPdfFileAction,
  GenerateOverviewPdfFileSuccessfulAction,
  GenerateOverviewPdfFileFailedAction,
  ShowDeleteTimeEntriesDialogAction,
  OpenBulkTimeEntryAddDialogAction,
  OpenBulkTimeEntryAddDialogSuccessAction,
  OpenBulkTimeEntryAddDialogFailedAction,
  ParseBulkTimeEntriesForUploadAction,
  ParseBulkTimeEntriesForUploadSuccessfulAction,
  ParseBulkTimeEntriesForUploadFailedAction,
  SubmitBulktEntriesToServerAction,
  SubmitBulktEntriesToServerSuccessfulAction,
} from '../actions/timesheet';

import { ReportService } from '../../services/report.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpResponse } from '@angular/common/http';
import { TimesheetService } from '../../services/timesheet.service';
import { saveFileFromBlobResponse } from 'app/shared/helpers';

@Injectable()
export class TimesheetEffects {

  getExcelDetailedFile$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateDetailedExcelFileAction>(ActionTypes.GENERATEEXCELFILEFROMDETAILVIEW),
      switchMap((action: GenerateDetailedExcelFileAction) =>
        this.reportService
          .getDetailedExcelFile(action.payload).pipe(
            map((res: HttpResponse<Blob>) => {
              saveFileFromBlobResponse(res);
              return new GenerateDetailedExcelFileSuccessAction(res);
            }),
            catchError(err => of(new GenerateDetailedExcelFileFailedAction(err))))
      )));

  getExcelOverviewFile$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateOverviewExcelFileAction>(ActionTypes.GENERATEOVERVIEWEXCEL),
      switchMap((action: GenerateOverviewExcelFileAction) =>
        this.reportService.getOverviewExcelFile(action.payload)
          .pipe(
            map((res: HttpResponse<Blob>) => {
              saveFileFromBlobResponse(res);
              return new GenerateOverviewExcelFileSuccessfulAction(res);
            }),
            catchError(err => of(new GenerateOverviewExcelFileFailedAction(err)))
          ))
    ));

  getPdfDetailedFile$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateDetailedPdfFileAction>(ActionTypes.GENERATEPDFFILEFROMDETAILVIEW),
      switchMap((action: GenerateDetailedPdfFileAction) =>
        this.reportService
          .getDetailedPdfFile(action.payload).pipe(
            map((res: HttpResponse<Blob>) => {
              saveFileFromBlobResponse(res);
              return new GenerateDetailedPdfFileSuccessAction(res);
            }),
            catchError(err => of(new GenerateDetailedPdfFileFailedAction(err))))
      )));

  getPdfOverviewFile$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateOverviewPdfFileAction>(ActionTypes.GENERATEOVERVIEWPDF),
      switchMap((action: GenerateOverviewPdfFileAction) =>
        this.reportService.getOverviewPdfFile(action.payload)
          .pipe(
            map((res: HttpResponse<Blob>) => {
              saveFileFromBlobResponse(res);
              return new GenerateOverviewPdfFileSuccessfulAction(res);
            }),
            catchError(err => of(new GenerateOverviewPdfFileFailedAction(err)))
          ))
    ));

  startApprovalForClient$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<StartApprovalProcessAction>(ActionTypes.STARTAPPROVALPROCESS),
      switchMap((action: StartApprovalProcessAction) => this.reportService.initiateApprovalForClient(action.payload).pipe(
        map(res => new StartApprovalProcessSucceededAction(res))
      ))));

  showTimesheetHistory = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENTIMESHEETHISTORYDIALOG),
      switchMap((action: OpenTimesheetHistoryDialogAction) => (
        this.timesheetService.showTimesheetHistoryDialog().pipe(
          map(() => new OpenTimesheetHistoryDialogSuccessfulAction(action.payload)),
          catchError(err => of(new OpenTimesheetHistoryDialogFailureAction(err)))
        )
      ))));

  showBulkTimeEntryAdd = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENBULKTIMEENTRYADDDIALOG),
      switchMap((action: OpenBulkTimeEntryAddDialogAction) => {
        return this.reportService.showBulkAddTimeDialog(action.payload)
          .pipe(
            map(() => new OpenBulkTimeEntryAddDialogSuccessAction()),
            catchError(err => of(new OpenBulkTimeEntryAddDialogFailedAction(err))));
      })
    ));

  fetchTimesheetHistoryFromServer = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.FETCHTIMESHEETHISTORYFROMSERVER),
      switchMap((action: FetchTimesheetHistoryFromServerAction) => (
        this.timesheetService.fetchTimesheetHistoryFromServer(action.payload).pipe(
          map((res) => new FetchTimesheetHistoryFromServerSuccessAction(res)),
          catchError(err => of(new FetchTimesheetHistoryFromServerFailureAction(err)))
        )
      ))));

  parseCsvToTimeData = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.PARSEBULKENTRIES),
      switchMap((action: ParseBulkTimeEntriesForUploadAction) =>
        this.reportService.parseTimeEntryCsv(action.payload).pipe(
          map((res) => new ParseBulkTimeEntriesForUploadSuccessfulAction(res)),
          catchError(err => of(new ParseBulkTimeEntriesForUploadFailedAction(err)))
        )
      )));

  uploadBulkEntries = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITBULKENTRIESTOSERVER),
      switchMap((action: SubmitBulktEntriesToServerAction) =>
        this.reportService.UploadBulkEntries(action.payload).pipe(
          map((res) => new SubmitBulktEntriesToServerSuccessfulAction(res)),
          catchError(err => of(new ParseBulkTimeEntriesForUploadFailedAction(err)))
        ))
    ));

  showDeleteTimeEntriesDialog = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SHOWDELETETIMEENTRIESDIALOG),
      switchMap((action: ShowDeleteTimeEntriesDialogAction) =>
        this.timesheetService.showDeleteTimeEntriesDialog(action.payload)
      )), { dispatch: false });

  constructor(private action$: Actions, private timesheetService: TimesheetService, private reportService: ReportService) { }
}
