
import { Observable, of, combineLatest, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, debounceTime, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  ActionTypes,
  GetInvoiceListSuccessAction,
  GetInvoiceListFailureAction,
  CreateInvoiceSuccessfulAction,
  CreateInvoiceFailedAction,
  CreateInvoiceAction,
  OpenGenerateFixedRateInvoiceDialog,
  OpenGenerateFixedRateInvoiceDialogSucceeded,
  OpenGenerateFixedRateInvoiceDialogFailed,
  CreateFixedRateInvoiceAction,
  CreateFixedRateInvoiceSuccessfulAction,
  CreateFixedRateInvoiceFailedAction,
  ResetInvoiceLoadingAction,
  OpenGenerateManualInvoiceDialogAction,
  OpenGenerateManualInvoiceDialogFailedAction,
  OpenGenerateManualInvoiceDialogSucceededAction,
  GenerateInvoicePreviewAction,
  GenerateInvoicePreviewFailedAction,
  GenerateInvoicePreviewCompleteAction,
  CreateManualInvoiceAction,
  CreateManualInvoiceSuccessfulAction,
  CreateManualInvoiceFailedAction,
  OpenInvoiceInformationDialogAction,
  OpenInvoiceInformationDialogSuccessAction,
  OpenInvoiceInformationDialogFailedAction,
  MarkInvoicesAsPaidAction,
  MarkInvoicesAsPaidFailureAction,
  DownloadInvoicesAction,
  DownloadInvoiceSuccessAction,
  GetInvoiceHistorySuccessAction,
  GetInvoiceHistoryFailureAction,
  GetInvoiceHistoryAction,
  RegenerateInvoiceFromSourceAction,
  RegenerateInvoiceFromSourceSuccessAction,
  RegenerateInvoiceFromSourceFailureAction,
  LoadCompanyDSOAction,
  LoadCompanyDSOSuccessAction,
  LoadCompanyDSOFailureAction,
  LoadClientDSOAction,
  LoadClientDSOSuccessAction,
  LoadClientDSOFailureAction,
  LoadCompanyCollectionSuccessAction,
  LoadCompanyCollectionFailureAction,
  GetInvoiceListAction,
  LoadCompanyCollectionAction
} from '../actions/invoice';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { ExternalInvoiceService } from '../../services/external-invoice.service';
import { getDashboardRole, getUserRole, State } from '../reducers';
import { InvoiceService } from 'app/services/invoice.service';
import { HttpResponse } from '@angular/common/http';
import { SnackbarService } from 'app/services/snackbar.service';
import { saveFileFromBlobResponse } from 'app/shared/helpers';

@Injectable()
export class InvoiceEffects {


  openGenerateFixedRateInvoiceDialog$ = createEffect(() => this.action$.pipe(
    ofType(OpenGenerateFixedRateInvoiceDialog),
    switchMap((res) => this.invoiceService.createNewEnterpriseClientDialog(res.project).pipe(
      map(() => OpenGenerateFixedRateInvoiceDialogSucceeded()),
      catchError(err => of(OpenGenerateFixedRateInvoiceDialogFailed()))
    ))
  ));

  downloadInvoice$ = createEffect(() => this.action$.pipe(
    ofType(DownloadInvoicesAction),
    withLatestFrom(this.store.select(getUserRole)),
    switchMap(([res, userRole]) =>
    {
      return forkJoin(
        res.invoices.map(invoice => {
          return this.invoiceService.downloadInvoice(invoice.invoiceId, userRole.companyId.toString())
            .pipe(
              map((res: HttpResponse<Blob>) => {
                saveFileFromBlobResponse(res);
                return null;
              }),
              catchError((error) => of(!error.accountInActive ? invoice.formattedInvoiceNumber : null))
            )
        })
      ).pipe(
        map(s => s.filter(s => s !== null)),
        map(s => {
          if (s.length) {
            this.snackbarService.displayServerError(
              'Failed to download invoice' +
              (s.length > 1 ? 's ' : ' ') +
              s.join(', ')
            );
          }
          return DownloadInvoiceSuccessAction();
        }),
      )
    }
  )));

  openGenerateManualInvoiceDialog$ = createEffect(() => this.action$.pipe(
    ofType(OpenGenerateManualInvoiceDialogAction),
    switchMap(() => this.invoiceService.createNewManualInvoiceDialog().pipe(
      map(() => OpenGenerateManualInvoiceDialogSucceededAction()),
      catchError(err => of(OpenGenerateManualInvoiceDialogFailedAction(err)))
    ))
  ));

  openInvoiceInformationDialog$ = createEffect(() => this.action$.pipe(
    ofType(OpenInvoiceInformationDialogAction),
    switchMap((res) => this.invoiceService.createInvoiceInformationDialog(res.invoice).pipe(
      map(() => OpenInvoiceInformationDialogSuccessAction()),
      catchError(err => of(OpenInvoiceInformationDialogFailedAction()))
    ))
  ));

  generateManualInvoicePreview$ = createEffect(() => this.action$.pipe(
    ofType(GenerateInvoicePreviewAction),
    switchMap((res) => this.invoiceService.getInvoicePreview(res.invoiceData, res.companyId).pipe(
      map((res: HttpResponse<Blob>) => {
        saveFileFromBlobResponse(res);
        return GenerateInvoicePreviewCompleteAction();
      }),
      catchError(err => of(GenerateInvoicePreviewFailedAction({ payload: err })))
    )),
    map(ResetInvoiceLoadingAction)
  ));

  generateManualInvoice$ = createEffect(() => this.action$.pipe(
    ofType(CreateManualInvoiceAction),
    switchMap((res) => this.invoiceService.createManualInvoice(res.invoiceData, res.companyId).pipe(
      map(() => CreateManualInvoiceSuccessfulAction()),
      catchError(err => of(CreateManualInvoiceFailedAction({ payload: err })))
    )),
  ));

  closeCreateManualInvoiceDialog = createEffect(() => this.action$
    .pipe(
      ofType(CreateManualInvoiceSuccessfulAction),
      delay(1200),
      tap(() => {
        this.invoiceService.closeManualInvoiceDialog();
      }),
      map(ResetInvoiceLoadingAction)
    ))

  closeCreateFixedRateInvoiceDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(CreateFixedRateInvoiceSuccessfulAction),
      debounceTime(1200),
      tap(() => {
        this.invoiceService.closeFixedRateDialog();
      }),
      debounceTime(300), // Stops slight flicker of button as dialog closes
      map(() => ResetInvoiceLoadingAction())
    ));

  createFixedRateinvoice$ = createEffect(() => this.action$.pipe(
    ofType(CreateFixedRateInvoiceAction),
    switchMap((res) => this.invoiceService.createFixedRateInvoice(res.project, res.companyId, res.invoiceTotal).pipe(
      map(() => {
        const currDate = new Date();
        return CreateFixedRateInvoiceSuccessfulAction({ nonce: currDate });
      }),
      catchError(err => of(CreateFixedRateInvoiceFailedAction({payload: err}))))
    ))
  );

  markInvoicesAsPaid$ = createEffect(() => this.action$.pipe(
    ofType(MarkInvoicesAsPaidAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([{invoiceIds, filterOptions}, role]) => {
      return this.externalInvoiceService.markInvoicesAsPaid(role, invoiceIds)
        .pipe(
          map(() => new GetInvoiceListAction(filterOptions)),
          catchError(err => {
            if (!err.accountInActive) {
              this.snackbarService.displayServerError(err);
            }
            return of(MarkInvoicesAsPaidFailureAction({payload: err}));
          })
        )
    })
  ));

  getInvoiceHistory$ = createEffect(() => this.action$.pipe(
    ofType(GetInvoiceHistoryAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.invoiceService.getInvoiceHistory({...action.payload, companyId: role.companyId})
        .pipe(
          map(res => GetInvoiceHistorySuccessAction({history: res})),
          catchError(err => of(GetInvoiceHistoryFailureAction({payload: err})))
        )
    })
  ));
  
  loadCompanyDSO$ = createEffect(() => this.action$.pipe(
    ofType(LoadCompanyDSOAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.invoiceService.getCompanyDSO(action.startDate, action.endDate, role.companyId)
        .pipe(
          map(res => LoadCompanyDSOSuccessAction({companyDSO: res})),
          catchError(err => of(LoadCompanyDSOFailureAction({error: err})))
        )
    })
  ));

  loadClientDSO$ = createEffect(() => this.action$.pipe(
    ofType(LoadClientDSOAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.invoiceService.getClientDSO(action.startDate, action.endDate, role.companyId)
        .pipe(
          map(res => LoadClientDSOSuccessAction({clientDSO: res})),
          catchError(err => of(LoadClientDSOFailureAction({error: err})))
        )
    })
  ));

  loadCompanyCollection$ = createEffect(() => this.action$.pipe(
    ofType(LoadCompanyCollectionAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.invoiceService.getCompanyCollection(action.startDate, action.endDate, role.companyId)
        .pipe(
          map(res => LoadCompanyCollectionSuccessAction({companyCollection: res})),
          catchError(err => of(LoadCompanyCollectionFailureAction({error: err})))
        )
    })
  ))

  regenerateInvoiceFromSource$ = createEffect(() => this.action$.pipe(
    ofType(RegenerateInvoiceFromSourceAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([{invoice, sendInvoiceEmail}, role]) => {
      return this.invoiceService.regenerateInvoiceFromSource({invoiceId: invoice.invoiceId, sendInvoiceEmail, companyId: role.companyId})
        .pipe(
          map(() => {
            this.snackbarService.displaySuccess('The invoice will be updated soon.');
            return RegenerateInvoiceFromSourceSuccessAction();
          }),
          catchError(err => {
            this.snackbarService.displayServerError('Failed to regenerate invoice ' + invoice.formattedInvoiceNumber);
            return of(RegenerateInvoiceFromSourceFailureAction({payload: err}));
          })
        )
    })
  ))

  loadAllInvoices$: Observable<Action> = createEffect(() =>
    this.action$
      .pipe(
        ofType(ActionTypes.GETINVOICELIST),
        withLatestFrom(this.store.select(getDashboardRole)),
        switchMap(([action, role]) => {
          return this.externalInvoiceService.getAllServerInvoices({...(action as GetInvoiceListAction).payload, companyId: role.companyId})
            .pipe(
              map(res => new GetInvoiceListSuccessAction(res)),
              catchError(err => of(new GetInvoiceListFailureAction(err)))
            )
        })
      )
    );

  CreateInvoice: Observable<Action> = createEffect(() =>
    combineLatest([
      this.action$.pipe(
        ofType<CreateInvoiceAction>(ActionTypes.CREATEINVOICEACTION)),
      this.store.select(getDashboardRole)])
      .pipe(
        switchMap(([action, role]) => this.externalInvoiceService.createInvoice(role, action.payload)
          .pipe(
            map((res) => new CreateInvoiceSuccessfulAction(res)),
            catchError(err => of(new CreateInvoiceFailedAction(err)))
          )
        )));
  
  resetInvoiceLoadingStatus: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.GETINVOICELISTSUCCESS, ActionTypes.GETINVOICELISTFAILURE),
      delay(1500),
      map(() => ResetInvoiceLoadingAction())
    )
  );

  constructor(
    private action$: Actions,
    private externalInvoiceService: ExternalInvoiceService,
    private invoiceService: InvoiceService,
    private snackbarService: SnackbarService,
    private store: Store<State>
  ) { }
}
