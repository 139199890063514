import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TimesheetHistoryComponent } from '../shared/dialogs/timesheet-history/timesheet-history.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ReportHistory, HistoryRequest } from '../models/ReportHistory';
import { environment } from '../../environments/environment';
import { TimeEntry } from 'app/models/timeentry';
import { UUID } from 'angular2-uuid';
import { DeleteTimeEntriesComponent } from 'app/shared/dialogs/delete-time-entries/delete-time-entries.component';

@Injectable()
export class TimesheetService {
  private showTimesheetHistoryDialogRef: MatDialogRef<TimesheetHistoryComponent>;
  private baseUrl = environment.server;
  private projectUrl = `${this.baseUrl}/Timesheet`;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog
  ) { }

  public showTimesheetHistoryDialog(): Observable<{}> {

    return new Observable(ob => {
      this.showTimesheetHistoryDialogRef = this.dialogService.open(TimesheetHistoryComponent, {
        panelClass: 'cartwheel-base-dialog',
        autoFocus: false
      });
      ob.next('');
    });
  }

  public fetchTimesheetHistoryFromServer(request: HistoryRequest): Observable<{}> {
    return this.http.post<ReportHistory>(`${this.projectUrl}/GetTimesheetHistory?`, {
      ...request,
      startDate: dayjs(request.date).format()
    });
  }

  public showDeleteTimeEntriesDialog(timeEntries: TimeEntry[]) {
    return new Observable(ob => {
      this.dialogService.open(DeleteTimeEntriesComponent, {
        width: '544px',
        data: { timeEntries }
      });
      ob.next('');
    });
  }

  public deleteTimeEntries(timeEntryIds: UUID[]): Observable<TimeEntry[]> {
    return this.http.post<TimeEntry[]>(`${this.projectUrl}/DeleteTimeEntries`, { timeEntryIds });
  }
}
