import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {

  public static ReturnServerErrorAsString(error: any): string {
    if (typeof (error) === 'string') {
      return error;
    }
    if (error.error) {
      if (this.isValidJson(error.error)) {
        error.error = JSON.parse(error.error);
      }
      if (typeof (error.error) === 'string') {
        return error.error;
      }
      if (error.error.error_description) {
        return error.error.error_description;
      }
      if (error.error.message) {
        return error.error.message;
      }
      if (error.error.detail) {
        return error.error.detail;
      }
    }
    if (error.statusText) {
      return error.statusText;
    }
    if (error.message) {
      return error.message;
    }
    return error;
  }

  private static isValidJson(string: string): boolean {
    try {
      JSON.parse(string);
      return true;
    } catch (error) {
      return false;
    }
  }
}
