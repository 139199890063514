import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { ExternalInvoice } from 'app/models/external-invoice';
import { ExternalDatasourceDisconnectRequest, InvoiceDataSourcePasswordAuthForm, InvoiceDataSourceState, SageIntacctDataSourceAuthForm, SageResponse } from 'app/models/invoice-data-source';
import { State, getInvoiceDataSources } from 'app/redux/reducers';
import { TogglInvoiceDataSourceDialogComponent } from 'app/shared/dialogs/toggl-invoice-data-source-dialog/toggl-invoice-data-source-dialog.component';
import { ExternalInvoicingSystems, SuccessStatus } from 'app/shared/enums';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceDataSourceService {
  private invoiceDataSourceDialogRef: MatDialogRef<TogglInvoiceDataSourceDialogComponent>;
  private dataSources$: Observable<InvoiceDataSourceState>;
  private baseUrl = `${environment.server}/ExternalService`;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog,
    private store: Store<State>,
  ) {
    this.dataSources$ = this.store.select(getInvoiceDataSources).pipe(skipWhile(p => !p));
  }

  public showInvoiceDataSourceDialog(payload: ExternalInvoice): Observable<any> {
    return this.dataSources$.pipe(
      take(1),
      switchMap(dataSources => new Observable(ob => {
        const config = new MatDialogConfig();
        config.data = {
          ...payload,
          ...dataSources[ExternalInvoicingSystems[payload.name]]
        }

        if (config.data.connectionStatus === SuccessStatus.Success) {
          config.width = '544px';
          config.hasBackdrop = true;
          config.autoFocus = false;
        } else {
          config.width = '500px';
          config.maxWidth = '80vw';
          config.hasBackdrop = true;
          config.disableClose = true;
        }

        this.invoiceDataSourceDialogRef = this.dialogService.open(TogglInvoiceDataSourceDialogComponent, config);
      }))
    );
  }

  public closeInvoiceDataSourceDialog(closeDialog: boolean): Observable<null> {
    if (this.invoiceDataSourceDialogRef && closeDialog) {
      this.invoiceDataSourceDialogRef.close();
      this.invoiceDataSourceDialogRef = null;
    }
    return of(null);
  }

  public connnectInvoiceDataSource(data: InvoiceDataSourcePasswordAuthForm): Observable<any> {
    const url = `${this.baseUrl}/ConnectInvoiceDatasource?companyId=${data.UserRole.companyId}`;
    return this.http.post(url, data, { responseType: 'text' });
  }

  public disconnectInvoiceDataSource(data: ExternalDatasourceDisconnectRequest, companyId: UUID): Observable<any> {
    const url = `${this.baseUrl}/disconnectinvoicedatasource?companyId=${companyId}`;
    return this.http.post(url, data, { responseType: 'text' });
  }

  public connectSageIntacctDatasource(data: SageIntacctDataSourceAuthForm): Observable<SageResponse> {
    const url = `${this.baseUrl}/sage/connect`;
    return this.http.post<SageResponse>(url, data);
  }
}
