
import { catchError, switchMap, map, tap, debounceTime, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';
import {
  ShowAddUserDialogAction, ShowAddUserDialogSuccessAction,
  ShowAddUserDialogFailureAction, SubmitAddNewUserAction,
  SubmitAddNewUserFailureAction,
  GetCompanyUsersAction,
  GetCompanyUsersSuccessAction,
  GetCompanyUsersFailureAction,
  ResetUserLoadingAction,
  UpdateUserInCompanyAction,
  UpdateUserInCompanySuccessAction,
  UpdateUserInCompanyFailureAction,
  ResendUserConfirmationAction,
  ResendUserConfirmationFailedAction,
  ResendUserConfirmationSucceededAction,
  OpenUserInformationDialogAction,
  OpenUserInformationDialogSuccessAction,
  OpenUserInformationDialogFailedAction,
  DeleteCompanyUsersAction,
  SubmitAddNewUserSuccessAction
} from '../actions/user';
import { of, Observable, forkJoin } from 'rxjs';
import { ActionTypes } from '../actions/user';
import { GetUserRolesAction } from '../actions/loginuser';
import { State, getDashboardRole, getUserRole } from '../reducers';
import { ApiErrorResponse } from 'app/models/apierror';
import { SnackbarService } from 'app/services/snackbar.service';
import { CustomFieldActions } from '../actions/customfields';
import { CustomFieldEntity } from 'app/models/custom-fields';

@Injectable()
export class UserEffects {
  OpenAddUserDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SHOWADDUSERDIALOG),
      switchMap((action: ShowAddUserDialogAction) => {
        return this.userService
          .showAddUserDialog(action.payload).pipe(
            map(() => new ShowAddUserDialogSuccessAction(action.payload)),
            catchError(err => of(new ShowAddUserDialogFailureAction(err))));
      })));

  submitAddNewUser$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITADDNEWUSER),
      switchMap((action: SubmitAddNewUserAction) => {
        return this.userService.submitAddNewUser(action.payload.addUserRequest).pipe(
            map(user => SubmitAddNewUserSuccessAction({user, filterOptions: action.payload.filterOptions})),
            tap(() => this.userService.closeAddUserDialog()),
            catchError(err => of(new SubmitAddNewUserFailureAction(err))),
          )
      })
    ));

  submitAddNewUserSuccess$ = createEffect(() => this.action$
    .pipe(
      ofType(SubmitAddNewUserSuccessAction),
      switchMap((action) => {
        const getUsersAction = GetCompanyUsersAction(action.filterOptions);
        const getCustomFieldEntriesAction = CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIds({
          entity: CustomFieldEntity.User,
          entityIds: [action.user.userId],
        });

        return [getUsersAction, getCustomFieldEntriesAction];
      })
    ));

  getCompanyUsers$ = createEffect(() => this.action$
    .pipe(
      ofType(GetCompanyUsersAction),
      withLatestFrom(this.store.select(getDashboardRole)),
      switchMap(([action, role]) => {
        return this.userService.getCompanyUsers({ ...action, companyId: role.companyId }).pipe(
          map(res => GetCompanyUsersSuccessAction({ users: res }),
            catchError(err => of(new GetCompanyUsersFailureAction(err))))
        )
      })
    )
  );

  openUserInformationDialog$ = createEffect(() => this.action$.pipe(
    ofType(OpenUserInformationDialogAction),
    switchMap((res) => this.userService.showUserInformationDialog(res).pipe(
      map(() => OpenUserInformationDialogSuccessAction()),
      catchError(err => of(OpenUserInformationDialogFailedAction()))
    ))
  ));

  resendTeamUserConfirmation: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.RESENDTEAMUSERCONFIRMATION),
      switchMap((action: ResendUserConfirmationAction) => {
        return this.userService.resendTeamUserConfirmation(action.payload).pipe(
          map(res => ResendUserConfirmationSucceededAction({ result: res })),
          catchError(res => of(new ResendUserConfirmationFailedAction(res)))
        )
      })
    ));

  closeAddUserDialogandResetLoadingState: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.SUBMITADDNEWUSERSUCCESS),
      debounceTime(1200),
      tap(() => this.userService.closeAddUserDialog()),
      debounceTime(300), // Stops slight flicker of button as dialog closes
      map(() => new ResetUserLoadingAction())
    ));

  resetLoadingState: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(
        ActionTypes.SUBMITADDNEWUSERFAILURE,
        ActionTypes.GETCOMPANYUSERSSUCCESSACTION,
        ActionTypes.UPDATEUSERINCOMPANYSUCCESS,
        ActionTypes.UPDATEUSERINCOMPANYFAILURE,
        ActionTypes.GETCOMPANYUSERSFAILUREACTION
      ),
      debounceTime(2200),
      map(() => new ResetUserLoadingAction())));

  deleteCompanyUser: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(ActionTypes.DELETECOMPANYUSERS),
      switchMap((action: DeleteCompanyUsersAction) => {
        return forkJoin(
          action.payload.users.map(s => {
            return this.userService.deleteCompanyUser(s)
              .pipe(
                map(() => null),
                catchError(error => of(!error.accountInActive ? `${s.firstName} ${s.lastName}` : null))
              )
          })
        ).pipe(
          map(s => s.filter(s => s !== null)),
          map(s => {
            if (s.length) {
              this.snackbarService.displayServerError(
                'Failed to delete user' +
                (s.length > 1 ? 's ' : ' ') +
                s.join(', ')
              );
            }
            this.userService.closeUserInformationDialog();
            return GetCompanyUsersAction(action.payload.filterOptions);
          })
        )
      })
    ));

  updateUserCompanyRole: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ActionTypes.UPDATEUSERINCOMPANY),
    switchMap((action: UpdateUserInCompanyAction) => {
      return this.userService
        .updateCompanyUser(action.payload.user).pipe(
          withLatestFrom(this.store.select(getUserRole)),
          mergeMap(([res, userRole]) => (userRole.userId === res.userId) ? [
            new GetUserRolesAction(),
            new UpdateUserInCompanySuccessAction(res)
          ] : [
            new UpdateUserInCompanySuccessAction(res)
          ]),
          map(() => GetCompanyUsersAction(action.payload.filterOptions)),
          tap(() => this.userService.closeUserInformationDialog()),
          catchError((err: ApiErrorResponse) => {
            if (!err.accountInActive) {
              this.snackbarService.displayServerError(err);
            }
            return of(new UpdateUserInCompanyFailureAction(err))
          })
        )
    })
  ));

  constructor(
    private action$: Actions,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private store: Store<State>
  ) { }
}
