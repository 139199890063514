import { ExternalInvoicingSystems, SuccessStatus } from "app/shared/enums";
import { UserCompanyRole } from "@cartwheel/web-components";
import { UUID } from "angular2-uuid";

export class InvoiceDataSourceState {
  [ExternalInvoicingSystems.Paidiem]: InvoiceDataSource;
}

export class InvoiceDataSource {
  connectionStatus: SuccessStatus;
}

export class InvoiceDataSourcePasswordAuthForm {
  username: string;
  password: string;
  UserRole: UserCompanyRole;
  InvoicingSystems: ExternalInvoicingSystems;
}

export class ExternalDatasourceDisconnectRequest {
  userRole: UserCompanyRole;
  invoicingSystems: ExternalInvoicingSystems;
}

export type InvoiceDataSourceName = 'Paidiem';

export class SageIntacctDataSourceAuthForm {
  sageCompanyId: string;
  senderId: string;
  senderPassword: string;
  userId: string;
  userPassword: string;
  companyId: UUID | string;

  constructor(data?: any) {
    if (data) {
      if (data.sageCompanyId) {
        this.sageCompanyId = data.sageCompanyId;
      }
      if (data.senderId) {
        this.senderId = data.senderId;
      }
      if (data.senderPassword) {
        this.senderPassword = data.senderPassword;
      }
      if (data.userId) {
        this.userId = data.userId;
      }
      if (data.userPassword) {
        this.userPassword = data.userPassword;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
    }
  }
}

export class SageResponse {
  success: boolean;
  data: string
}