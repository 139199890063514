import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { CartwheelSnackbarComponent } from '@cartwheel/web-components';
import { ErrorService } from './error.service';
import { ApiErrorResponse } from 'app/models/apierror';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private matSnackBar: MatSnackBar) { }

  public displayServerError(error: string | ApiErrorResponse): void {
    const message = ErrorService.ReturnServerErrorAsString(error);
    this.displayError(message);
  }

  public displayError(message: string): void {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 10000,
      data: {
        message: message,
        type: 'error'
      }
    }
    this.matSnackBar.openFromComponent(CartwheelSnackbarComponent, config);
  }

  public displaySuccess(message: string): void {
    const config: MatSnackBarConfig = {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 10000,
      data: {
        message: message,
        type: 'success'
      }
    }
    this.matSnackBar.openFromComponent(CartwheelSnackbarComponent, config);
  }
}
