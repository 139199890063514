
import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { State } from '../../../redux/reducers';
import {
  CloseInvoiceDialogAction,
} from '../../../redux/actions/settings';
import { SettingsContainerComponent } from '../../../settings/settings-container.component';
import { ExternalInvoice, Provider } from '../../../models/external-invoice';
import { ExternalInvoicingSystems, SuccessStatus } from '../../enums';
import {
  StartUserAuthorizationAction,
  DisconnectProviderAction
} from '../../../redux/actions/ftux';
import { TogglOpenDialogAction } from 'app/redux/actions/approver';

@Component({
  standalone: true,
  selector: 'app-invoice',
  templateUrl: './invoice-client-connection.component.html',
  styleUrls: ['./invoice-client-connection.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class InvoiceComponent implements OnInit {
  connectionStatusPlaceHolder: string;
  defaultText: string;

  private store = inject(Store<State>);
  private dialog = inject(MatDialogRef<SettingsContainerComponent>);
  private router = inject(Router);
  private data: Provider & ExternalInvoice = inject(MAT_DIALOG_DATA);

  constructor(
  ) {
    this.dialog.afterClosed().subscribe(() => {
      this.closeDialog();
    });
  }

  ngOnInit() {
    if (this.data.connectionStatus === SuccessStatus.Success) {
      this.connectionStatusPlaceHolder = 'disconnect';
    } else {
      this.connectionStatusPlaceHolder = 'connect'
    }
    if (this.data.name !== ExternalInvoicingSystems[ExternalInvoicingSystems.Cartwheel]) {
      this.defaultText = `Are you sure you want to ${this.connectionStatusPlaceHolder} ${this.data.name} ${(this.connectionStatusPlaceHolder === 'connect') ? 'to' : 'from'} Cartwheel?`;
    } else {
      if (this.connectionStatusPlaceHolder === 'connect') {
        this.defaultText = 'Are you sure you want to use Cartwheel as your invoicing provider?';
      } else {
        this.defaultText = 'Are you sure you want to disconnect Cartwheel as your invoicing provider?';
      }
    }

    // });
  }
  /* When the user clicks 'no' on the dialog, merely close the dialog */
  public closeDialog() {
    this.dialog.close();
    this.store.dispatch(new CloseInvoiceDialogAction(this.data));
  }
  /* After the user clicks 'yes' when prompted if he/she would like to disconnect/connect from
  * the selected service, a new action is called and updates the connection status accordingly */
  public changeInvoiceConnectionStatus() {
    if (this.data.connectionStatus !== SuccessStatus.Success) {
      if (
        ExternalInvoicingSystems[this.data.name] !== ExternalInvoicingSystems.Qwil &&
        ExternalInvoicingSystems[this.data.name] !== ExternalInvoicingSystems.BillCom
      ) {
        this.store.dispatch(new StartUserAuthorizationAction({
          serviceName: this.data.name,
          redirectPath: this.router.url.substring(1)
        }));
      } else {
        this.store.dispatch(new TogglOpenDialogAction(this.data));
      }
    } else {
      this.store.dispatch(new DisconnectProviderAction(this.data));
    }

    this.dialog.close();
  }
}

