
import { switchMap, catchError, map, debounceTime, withLatestFrom } from 'rxjs/operators';
import { LoadClientsWithoutBilledRatesSuccessAction } from '../actions/client';
import { ClientService } from '../../services/client.service';
import { of, Observable, zip } from 'rxjs';
import {
  ActionTypes,
  FtuxCompleteAction,
  GenerateUserTokenAction,
  GenerateUserTokenErrorAction,
  GenerateUserTokenSuccessfulAction,
  GetExternalRequestTokenAction,
  GetRequestTokenErrorAction,
  ImportExternalClientsAction,
  StartUserAuthorizationAction,
  UserAuthorizationErrorAction,
  UserAuthorizationSuccessAction,
  DisconnectProviderAction,
  DisconnectProviderSuccessfulAction,
  DisconnectProviderErrorAction,
  ExternalInvoiceProviderLoginAction,
  ExternalInvoiceProviderLoginSuccessAction,
  ExternalInvoiceProviderLoginFailureAction,
  ExternalInvoiceProviderLoginCompleteAction,
  GenerateUserTokenCompleteAction,
} from '../actions/ftux';
import { ExternalInvoiceService } from '../../services/external-invoice.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { OAuthRequest } from '../../models/OAuthIdent';
import { ApiErrorResponse } from 'app/models/apierror';
import { AuthService } from 'app/services/auth.service';
import { GetUserSettingsAction } from '../actions/settings';
import { getDashboardRole, State } from '../reducers';

@Injectable()
export class ExternalInvoicerEffects {

  public getRequestToken$ = createEffect(() => this.action$
    .pipe(
      ofType<GetExternalRequestTokenAction>(ActionTypes.GETREQUESTTOKEN),
      switchMap(action => {
        return this.externalService.getRequestToken(action.payload).pipe(
          map((res: OAuthRequest) => new StartUserAuthorizationAction(Object.assign({}, action.payload, res))),
          catchError(error => of(new GetRequestTokenErrorAction({ ...error, provider: action.payload.serviceName }))));
      })
    )
  );

  public getAuthorizationInformation$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<StartUserAuthorizationAction>(ActionTypes.STARTUSERAUTHORIZATION),
      withLatestFrom(this.store.select(getDashboardRole)),
      switchMap(([action, role]) => {
        return this.externalService.authorizeCartwheelForExternal(action.payload, role).pipe(
          map(value => new UserAuthorizationSuccessAction(action.payload.serviceName)),
          catchError(error => of(new UserAuthorizationErrorAction({ ...error, service: action.payload.serviceName }))));
      })
    )
  );

  public getAccessToken$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateUserTokenAction>(ActionTypes.GENERATEUSERTOKEN),
      switchMap(action => {
        return this.externalService.submitAccessTokenToServer(action.payload).pipe(
          map(value => new GenerateUserTokenSuccessfulAction({ provider: action.payload.provider, result: value })),
          catchError(error => of(new GenerateUserTokenErrorAction({ ...error, provider: action.payload.provider }))));
      })
    )
  );

  accessTokenSubmissionSuccess$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateUserTokenSuccessfulAction>(ActionTypes.GENERATEUSERTOKENSUCCESSFUL),
      debounceTime(1500),
      map(action => new GenerateUserTokenCompleteAction())
    ));

  invoiceProviderLogin$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EXTERNALINVOICEPROVIDERLOGIN),
      switchMap((action: ExternalInvoiceProviderLoginAction) => {
        return this.externalService.passwordLogin(action.payload).pipe(
          map(res => new ExternalInvoiceProviderLoginSuccessAction({
            provider: action.payload.provider,
            result: res,
            billComTeamProvider: action.payload.billComTeamProvider
          })),
          catchError(error => of(new ExternalInvoiceProviderLoginFailureAction({
            provider: action.payload.provider,
            error,
            billComTeamProvider: action.payload.billComTeamProvider
          })))
        )
      })
    ));

  invoiceProviderLoginSuccess$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<ExternalInvoiceProviderLoginSuccessAction>(ActionTypes.EXTERNALINVOICEPROVIDERLOGINSUCCESS),
      debounceTime(1000),
      map(action => new ExternalInvoiceProviderLoginCompleteAction({
        provider: action.payload.provider,
        closeDialog: true,
        billComTeamProvider: action.payload.billComTeamProvider
      }))
    ));

  invoiceProviderLoginFailure$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<ExternalInvoiceProviderLoginFailureAction>(ActionTypes.EXTERNALINVOICEPROVIDERLOGINFAILURE),
      debounceTime(2500),
      map(action => new ExternalInvoiceProviderLoginCompleteAction({
        provider: action.payload.provider,
        closeDialog: true,
        billComTeamProvider: action.payload.billComTeamProvider
      }))
    ));

  invoiceProviderLoginComplete$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EXTERNALINVOICEPROVIDERLOGINCOMPLETE),
      switchMap((action: ExternalInvoiceProviderLoginCompleteAction) => {
        return this.authService.closeTogglDialog(action.payload.closeDialog, action.payload.billComTeamProvider);
      })
    ), { dispatch: false });

  public showImportDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<GenerateUserTokenSuccessfulAction>(ActionTypes.GENERATEUSERTOKENSUCCESSFUL),
      switchMap(action => {
        return this.externalService.showClientImportDialog(action.payload.provider, action.payload.result).pipe(
          map(result => {
            return new FtuxCompleteAction(result)
          }),
          catchError(error => of(new GetRequestTokenErrorAction(error))));
      })));


  public importExternalClient$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<ImportExternalClientsAction>(ActionTypes.IMPORTEXTERNALCLIENTS),
      switchMap(action => {
        return zip(
          this.clientService.GetAllClientsFromServer(),
          this.externalService.ImportExternalClients(action.payload)
        ).pipe(map((clientArray) => {
          const clients = clientArray[0].concat(clientArray[1]);
          return new LoadClientsWithoutBilledRatesSuccessAction(clients)
        }),
          catchError((err: ApiErrorResponse) => of(new GetRequestTokenErrorAction({ ...err, provider: action.payload }))))
      })
    )
  );

  public disconnectProvider$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType<DisconnectProviderAction>(ActionTypes.DISCONNECTPROVIDER),
      switchMap(action => {
        return this.externalService.disconnectProvider(action.payload).pipe(
          map(() => new DisconnectProviderSuccessfulAction(action.payload)),
          catchError((err: ApiErrorResponse) =>
            of(new DisconnectProviderErrorAction({ ...err, provider: action.payload.name }))));
      })
    )
  );

  constructor(
    private action$: Actions,
    private clientService: ClientService,
    private externalService: ExternalInvoiceService,
    private router: Router,
    private authService: AuthService,
    private store: Store<State>
  ) { }
}
