import { UUID } from 'angular2-uuid';
import { NotificationStatus, NotificationType } from 'app/shared/enums';

export class Notification {
  notificationId?: UUID;
  message?: string;
  status?: NotificationStatus;
  type?: NotificationType;
  userId?: UUID;
  createdAt?: Date;

  constructor(data?) {
    if (data) {
      this.notificationId = data.notificationId;
      this.message = data.message;
      this.status = data.status;
      this.type = data.type;
      this.userId = data.userId;
      this.createdAt = new Date(data.createdAt);
    }
  }
}
