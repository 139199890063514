import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelTextareaComponent } from '@cartwheel/web-components';
import { AsyncPipe } from '@angular/common';
import { HideCloseAccountDialogAction, SubmitCloseAccountAction } from '../../../redux/actions/settings';
import { getGSettingsLoading, State } from '../../../redux/reducers';
import { SuccessStatus } from '../../../shared/enums';
import { CompanyFeedback } from '../../../models/companyfeedback';

@Component({
  standalone: true,
  selector: 'app-close-account',
  templateUrl: './close-account.component.html',
  styleUrls: ['./close-account.component.scss'],
  imports: [AsyncPipe, CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelTextareaComponent]
})

export class CloseAccountComponent implements OnInit {
  message: string = '';
  loading$: Observable<SuccessStatus>;

  private store = inject(Store<State>);

  ngOnInit(): void {
    this.loading$ = this.store.select(getGSettingsLoading);
  }

  closeDialog(): void {
    this.store.dispatch(new HideCloseAccountDialogAction());
  }

  submit(): void {
    this.store.dispatch(new SubmitCloseAccountAction(
      new CompanyFeedback({ CommentText: this.message })
    ));
  }
}
