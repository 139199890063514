import { Component, DestroyRef, HostListener, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserCompanyRole } from '@cartwheel/web-components';
import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Project } from 'app/models/project';
import { UserSettings } from 'app/models/user-settings';
import { CreateFixedRateInvoiceAction } from 'app/redux/actions/invoice';
import { State, getDashboardRole, getGSettingsGlobalSettingsModel, getInvoiceLoading } from 'app/redux/reducers';
import { PATTERNS } from 'app/shared/consts';
import { SuccessStatus } from 'app/shared/enums';
import { SaveButtonComponent } from 'app/shared/components/save-button/save-button.component';

@Component({
  standalone: true,
  selector: 'app-generate-fixed-rate-invoice',
  templateUrl: './generate-fixed-rate-invoice.component.html',
  styleUrls: ['./generate-fixed-rate-invoice.component.scss'],
  imports: [AsyncPipe, CurrencyPipe, MatIconModule, MatRadioModule, MatFormFieldModule, FormsModule, ReactiveFormsModule, SaveButtonComponent]
})
export class GenerateFixedRateInvoiceComponent implements OnInit {

  invoicesLoading$: Observable<boolean | SuccessStatus>;
  getGlobalSettingsModel$: Observable<UserSettings>;
  selectedproject: Project;
  private currentRole$: Observable<UserCompanyRole>;
  private currentRole: UserCompanyRole;

  loadingProject: boolean | SuccessStatus;
  successStatus = SuccessStatus;
  projectName: string;
  componentActive = true;

  submitFixedRateForm: UntypedFormGroup;
  selectedProject: Project;
  unfilteredProjects: Project[] = [];
  filteredProjects: Project[] = [];
  selectedPercentage = 0;
  selectedAmount = 0;
  invoiceTotal = 0;
  radioSelection: 'percentage' | 'amount' = 'percentage';
  getGlobalSettingsModel: UserSettings;
  percentageValid: boolean = false;
  amountValid: boolean = false;
  constructor(
    @Inject(MatDialogRef) public dialog: MatDialogRef<GenerateFixedRateInvoiceComponent>,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(Store) private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) data: Project,
    private destroyRef: DestroyRef
  ) {
    this.submitFixedRateForm = this.fb.group({
      'invoiceTotal': [this.invoiceTotal, [Validators.required, Validators.minLength(0), Validators.pattern(PATTERNS.Decimal)]],
      'projectId': [data.projectId, [Validators.required]],
      'radioSelection': [this.radioSelection]
    });

    this.selectedProject = data;
  }
  ngOnInit(): void {
    this.currentRole$ = this.store.select(getDashboardRole);
    this.currentRole$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(res => this.currentRole = res);
    this.getGlobalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.invoicesLoading$ = this.store.select(getInvoiceLoading);

    this.getGlobalSettingsModel$.pipe(
      takeUntilDestroyed(this.destroyRef)
    )
      .subscribe((gModel: UserSettings) => {
        this.getGlobalSettingsModel = new UserSettings(gModel);
      });

  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.dialog.close();
  }

  public closeDialog() {
    this.dialog.close();
  }

  updateRadioSelection(event) {
    const val = event.value;
    this.radioSelection = val;
  }

  public onSubmit() {
    this.createInvoiceData();
  }

  validatePercentageIsValid(event: Event) {
    const selectedValue = (event.target as HTMLInputElement).value;
    const isNumber = PATTERNS.Decimal.test(selectedValue);
    if (!isNumber || +selectedValue <= 0 || +selectedValue > 100) {
      this.percentageValid = false;
      this.invoiceTotal = 0;
    } else {
      this.percentageValid = true;
      this.invoiceTotal = (+selectedValue / 100) * this.selectedProject.projectTotal;
    }
  }

  validateAmountIsValid(event: Event) {
    const selectedValue = (event.target as HTMLInputElement).value;
    const isNumber = PATTERNS.Decimal.test(selectedValue);
    if (!isNumber || +selectedValue > this.selectedProject.projectTotal || (this.selectedProject.projectTotal - this.selectedProject.totalPaid < +selectedValue) || +selectedValue === 0) {
      this.amountValid = false;
      this.invoiceTotal = 0;
    } else {
      this.amountValid = true;
      this.invoiceTotal = +selectedValue;
    }
  }

  public createInvoiceData() {
    this.store.dispatch(CreateFixedRateInvoiceAction({ project: this.selectedProject, companyId: this.currentRole.companyId as string, invoiceTotal: this.invoiceTotal }));
  }

}
