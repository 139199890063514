import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UUID } from 'angular2-uuid';
import { ClientDSO, CompanyCollection, CompanyDSO, CompanyInvoice, CompanyInvoiceHistoryItem, CompanyInvoiceHistoryPagedRequest } from 'app/models/company-invoice';
import { PagedResponse } from 'app/models/pagination';
import { Project } from 'app/models/project';
import { ManualInvoiceRequest, ManualInvoiceRequestPreview } from 'app/models/ReportRequest';
import { GenerateFixedRateInvoiceComponent } from 'app/shared/dialogs/generate-fixed-rate-invoice/generate-fixed-rate-invoice.component';
import { GenerateManualInvoiceComponent } from 'app/team/invoices/invoices-components/generate-manual-invoice/generate-manual-invoice.component';
import { InvoiceInformationDialogComponent } from 'app/team/invoices/invoices-components/invoice-information-dialog/invoice-information-dialog.component';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private projectBaseUrl = `${environment.server}/project`;
  private createFixedRateInvoiceDialogRef: MatDialogRef<GenerateFixedRateInvoiceComponent>;
  private createManualInvoiceDialogRef: MatDialogRef<GenerateManualInvoiceComponent>;
  private createdInvoiceInformationDialogRef: MatDialogRef<InvoiceInformationDialogComponent>;
  private readonly headers;
  constructor(private dialogService: MatDialog,
    private http: HttpClient,) {
    this.headers = new HttpHeaders().append('Content-Type', 'application/json');
  }

  public createNewEnterpriseClientDialog(project: Project) {
    return new Observable(ob => {
      this.createFixedRateInvoiceDialogRef = this.dialogService.open(GenerateFixedRateInvoiceComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: project
      });

      ob.next('');
    });
  }

  public createNewManualInvoiceDialog() {
    return new Observable(ob => {
      this.createManualInvoiceDialogRef = this.dialogService.open(GenerateManualInvoiceComponent, {
        panelClass: 'cartwheel-base-dialog',
        disableClose: true
      });
      ob.next('');
    });
  }

  public createInvoiceInformationDialog(invoice: CompanyInvoice) {
    return new Observable(ob => {
      this.createdInvoiceInformationDialogRef = this.dialogService.open(InvoiceInformationDialogComponent, {
        panelClass: 'cartwheel-base-dialog',
        disableClose: true,
        data: invoice
      });
      ob.next('');
    });
  }


  closeManualInvoiceDialog() {
    if (this.createManualInvoiceDialogRef) {
      this.createManualInvoiceDialogRef.close();
      this.createManualInvoiceDialogRef == null;
    }
  }

  public closeFixedRateDialog() {
    if (this.createFixedRateInvoiceDialogRef) {
      this.createFixedRateInvoiceDialogRef.close();
      this.createFixedRateInvoiceDialogRef = null;
    }
  }


  public getInvoicePreview(report: ManualInvoiceRequestPreview, companyId: string): Observable<HttpResponse<Blob>> {
    return this.http.post(`${environment.server}/Invoice/GenerateClientInvoicePreview?companyId=${companyId}`, JSON.stringify(report as ManualInvoiceRequestPreview), {
      responseType: 'blob',
      observe: 'response',
      headers: this.headers
    });
  }

  public createManualInvoice(report: ManualInvoiceRequest, companyId: string) {
    return this.http.post(`${environment.server}/Invoice/GenerateClientInvoice?companyId=${companyId}`, JSON.stringify(report), { headers: this.headers });
  }

  public createFixedRateInvoice(project: Project, companyId: string, invoiceTotal: number) {
    return this.http.post(`${this.projectBaseUrl}/CreateInvoiceForFixedRateProject?companyid=${companyId}&projectId=${project.projectId}&invoiceTotal=${invoiceTotal}`, null);
  }

  public downloadInvoice(invoiceId: string, companyId: string) {
    return this.http.get(`${environment.server}/file/GetInvoicePdf?invoiceId=${invoiceId}&companyId=${companyId}`, {
      responseType: 'blob',
      observe: 'response',
      headers: this.headers
    });
  }

  public getInvoiceHistory(payload: CompanyInvoiceHistoryPagedRequest): Observable<PagedResponse<CompanyInvoiceHistoryItem>> {
    var params = new HttpParams();
    params = params
      .append('limit', payload.limit)
      .append('invoiceId', payload.invoiceId)
      .append('companyId', payload.companyId.toString())
      .append('page', payload.page);
    if (payload.filterBy != null && payload.filterBy != undefined) {
      params = params.append('filterBy', payload.filterBy);
    }

    return this.http.get<PagedResponse<CompanyInvoiceHistoryItem>>(
      `${environment.server}/Invoice/GetInvoiceHistory`,
      { params }
    );
  }

  public regenerateInvoiceFromSource(payload: { invoiceId: string, sendInvoiceEmail: boolean, companyId: UUID }) {
    const params = new HttpParams({
      fromObject: JSON.parse(JSON.stringify(payload))
    });
    return this.http.get(
      `${environment.server}/Invoice/RegenerateInvoiceFromSource`,
      { params }
    )
  }

  public getCompanyDSO(startDate: Date, endDate: Date, CompanyId: UUID): Observable<CompanyDSO> {
    const params = new HttpParams({
      fromObject: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        CompanyId: CompanyId.toString()
      }
    })
    return this.http.get<CompanyDSO>(`${environment.server}/invoice/GetCompanyDSO`, { params });
  }

  public getClientDSO(startDate: Date, endDate: Date, CompanyId: UUID): Observable<Array<ClientDSO>> {
    const params = new HttpParams({
      fromObject: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        CompanyId: CompanyId.toString()
      }
    })
    return this.http.get<Array<ClientDSO>>(`${environment.server}/invoice/GetClientDSO`, { params });
  }

  public getCompanyCollection(startDate: Date, endDate: Date, CompanyId: UUID): Observable<CompanyCollection> {
    return this.http.post<CompanyCollection>(`${environment.server}/invoice/RetrieveCollectionInformation`, {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      companyId: CompanyId.toString()
    });
  }
}
