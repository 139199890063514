
import { from, Observable, of } from 'rxjs';
import { combineLatestWith, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserRole } from '@cartwheel/web-components';
import { Store } from '@ngrx/store';
import { getIsAuthenticated, State } from '../redux/reducers';
import { SetAccessTokenAction } from 'app/redux/actions/loginuser';
import { NgForage } from 'ngforage';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService  {
  constructor(private authService: AuthService, private lStorage: NgForage,
    private _store: Store<State>, private router: Router) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const url: string = state.url;
    return this.checkIfAuthenticated(url);
  }

  public checkIfAuthenticated(url: string): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.select(getIsAuthenticated).pipe(
      switchMap(authenticated => {
        if (authenticated) {
          return of(true);
        }
        return from(this.lStorage.getItem('token'))
          .pipe(
            take(1),
            combineLatestWith(from(this.lStorage.getItem('currentRole'))),
            switchMap(([token, role]) => {
              return this.checkLocalStorage(url, token as string, role as string);
            })
          )
      }));
  }


  private checkLocalStorage(url: string, access_token: string, role: string): Observable<boolean> {
    if (access_token) {
      this._store.dispatch(new SetAccessTokenAction(access_token));
      this.authService.setUserProfile();

      if (!!role && url === '/') {
        try {
          JSON.parse(role).userRole === UserRole.User
            ? this.router.navigate(['/Home'])
            : this.router.navigate(['/Team']);
          return of(false);
        } catch (error) {
        }
      }
      return of(true);
    }
    if (!environment.production)
      console.log('auth failed')
    this.authService.logout();
    this.authService.redirectUrl = url;
    this.router.navigate(['/Login']);
    return of(false);
  }
};
