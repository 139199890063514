import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelInputComponent } from '@cartwheel/web-components';
import { ExternalInvoice } from 'app/models/external-invoice';
import { InvoiceDataSource, SageIntacctDataSourceAuthForm } from 'app/models/invoice-data-source';
import { InvoiceDataSourceActions } from 'app/redux/actions';
import { State, getInvoiceDataSourceError, getInvoiceDataSources } from 'app/redux/reducers';
import { ExternalInvoicingSystems, SuccessStatus } from 'app/shared/enums';
import { PipesModule } from 'app/pipes/pipes.module';

@Component({
  standalone: true,
  selector: 'app-toggl-invoice-data-source-dialog',
  templateUrl: './toggl-invoice-data-source-dialog.component.html',
  styleUrls: ['./toggl-invoice-data-source-dialog.component.scss'],
  imports: [AsyncPipe, PipesModule, ReactiveFormsModule, CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelInputComponent]
})
export class TogglInvoiceDataSourceDialogComponent implements OnInit {
  error$: Observable<string>;
  loadingStatus: SuccessStatus;
  form: UntypedFormGroup;
  SuccessStatus = SuccessStatus;
  externalInvoicingSystems = ExternalInvoicingSystems;
  private destorRef = inject(DestroyRef);
  private store = inject(Store<State>);
  private dialog = inject(MatDialogRef<TogglInvoiceDataSourceDialogComponent>);
  public data: InvoiceDataSource & ExternalInvoice = inject(MAT_DIALOG_DATA)

  ngOnInit(): void {
    if (this.getIsSageIntacct()) {
      this.form = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
        senderId: new UntypedFormControl('', Validators.required),
        senderPassword: new UntypedFormControl('', Validators.required),
        sageCompanyId: new UntypedFormControl('', Validators.required),
      });
    } else {
      this.form = new UntypedFormGroup({
        username: new UntypedFormControl('', Validators.required),
        password: new UntypedFormControl('', Validators.required),
      });
    }

    this.error$ = this.store.select(getInvoiceDataSourceError);
    this.store.select(getInvoiceDataSources)
      .pipe(skipWhile(p => !p), takeUntilDestroyed(this.destorRef))
      .subscribe(status => {
        const dataSource = ExternalInvoicingSystems[this.data.name] as ExternalInvoicingSystems;
        this.loadingStatus = status[dataSource].connectionStatus;
      });
  }

  closeDialog(): void {
    this.dialog.close();
    this.store.dispatch(InvoiceDataSourceActions.closeInvoiceDataSourceDialogAction());
  }

  connect(): void {
    if (this.getIsSageIntacct()) {
      this.store.dispatch(InvoiceDataSourceActions.sageIntacctDataSourceLoginAction({
        sageIntacctDataSourceAuthData: {
          ...new SageIntacctDataSourceAuthForm(this.form.value),
          userId: this.form.value.username,
          userPassword: this.form.value.password
        }
      }));
    } else {
      this.store.dispatch(InvoiceDataSourceActions.invoiceDataSourceLoginAction({
        invoiceDataSourceAuthData: {
          ...this.form.value,
          InvoicingSystems: ExternalInvoicingSystems[this.data.name]
        }
      }));
    }
  }

  disConnect(): void {
    this.store.dispatch(InvoiceDataSourceActions.disconnectInvoiceDataSourceAction({ invoicingSystems: ExternalInvoicingSystems[this.data.name] }));
  }

  getIsSageIntacct(): boolean {
    const sageName = this.externalInvoicingSystems[this.externalInvoicingSystems.SageIntacct];
    
    return this.data.name === sageName;
  }
}
