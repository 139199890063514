import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";
import { VERSION } from './environments/version';


const integrations: any[] = [new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation }),]

if (environment.production) {
  enableProdMode();
  // integrations.push(new Sentry.Replay())
}

if (!environment.production) {
  console.log('Sentry access token: %s', process.env.SENTRY_AUTH_TOKEN);
}

const isLocal = 
  window.location.hostname === 'localhost' || 
  window.location.hostname === '127.0.0.1' || 
  window.location.hostname === '0.0.0.0';

Sentry.init({
  dsn: environment.raven.dsn,
  release: `${environment.currentEnv}@${VERSION.version}`,
  environment: environment.currentEnv,
  integrations: integrations,
  tracesSampleRate: environment.production ? .1 : 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/cartwheel\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: !environment.production,
  enabled: !isLocal
})


const bootstrap = () => {
  return platformBrowserDynamic().bootstrapModule(AppModule);
};
bootstrap().catch(err => {
  if (!environment.production) {
    console.log(err);
  }
});

