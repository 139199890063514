import dayjs from 'dayjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, HostListener, OnInit, Inject, DestroyRef } from '@angular/core';
import { AddNewProject } from '../../../redux/actions/project';
import {
  getLoadedClients, getProjectLoading, getProjectSelectedSingle, getProjectsList, getSelectedClient,
  State
} from '../../../redux/reducers/index';
import { UUID } from 'angular2-uuid';
import { Project } from '../../../models/project';
import { Client } from '../../../models/Client';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent, CartwheelSelectOptions } from '@cartwheel/web-components';
import { AsyncPipe } from '@angular/common';
import { OpenClientAddDialogAction, SelectClientAction } from '../../../redux/actions/client';
import { Status, ClientStatus, SuccessStatus } from '../../enums';
import { PipesModule } from 'app/pipes/pipes.module';

@Component({
  standalone: true,
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
  imports: [AsyncPipe, ReactiveFormsModule, CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent, PipesModule]
})
export class AddProjectComponent implements OnInit {
  projectsLoading$: Observable<boolean | SuccessStatus>;
  clients$: Observable<Client[]>;
  selectedClient$: Observable<Client>;
  projects$: Observable<Project[]>;
  selectedProject$: Observable<Project>;

  loadingProject: boolean | SuccessStatus;

  submitProjectEntry: UntypedFormGroup;
  selectedClient: Client;
  clients: Client[];
  selectedProject: Project;
  unfilteredProjects: Project[] = [];
  filteredProjects: Project[] = [];
  public clientsOptions: CartwheelSelectOptions<Client> = [];
  public successStatus = SuccessStatus;

  constructor(
    @Inject(MatDialogRef) public dialog: MatDialogRef<AddProjectComponent>,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    @Inject(Store) private store: Store<State>,
    private destroyRef: DestroyRef
  ) {
    this.submitProjectEntry = this.fb.group({
      'selectedClient': ['', [Validators.required]],
      'projectName': ['', [Validators.required]],
    });

    this.submitProjectEntry.get('selectedClient').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((client: Client) => {
        this.store.dispatch(new SelectClientAction(client));
      });
  }

  ngOnInit() {
    this.selectedProject = new Project();
    this.selectedProject$ = this.store.select(getProjectSelectedSingle);
    this.clients$ = this.store.select(getLoadedClients);
    this.projectsLoading$ = this.store.select(getProjectLoading);
    this.projects$ = this.store.select(getProjectsList);
    this.selectedClient$ = this.store.select(getSelectedClient);

    this.selectedProject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(proj => {
      if (proj && this.selectedClient) {
        this.selectedProject = proj;
      } else {
        this.selectedProject = new Project();
      }
    });

    this.projectsLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(loading => {
      this.loadingProject = loading;
    });

    this.clients$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(clients => {
      this.clients = clients.filter((c) => (c.status !== ClientStatus.Deleted
        && c.status !== ClientStatus.Archived));
      this.clientsOptions = this.clients.map(client => ({
        label: client.clientName,
        value: client
      }))
    });

    this.projects$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projs => {
      this.unfilteredProjects = projs;
      if (this.selectedClient) {
        this.filteredProjects = projs.filter(proj => {
          return proj.clientId === this.selectedClient.clientID;
        });
      }
    });

    this.selectedClient$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(client => {
      if (client) {
        this.selectedClient = client;
        this.filteredProjects = this.unfilteredProjects.filter(proj => proj.clientId === this.selectedClient.clientID);
      }
    });
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.dialog.close();
  }

  public closeDialog() {
    this.dialog.close();
  }

  public onSubmit() {
    this.addNewProject();
  }

  public addNewProject() {
    const newProject = new Project();
    newProject.projectId = UUID.UUID();
    newProject.clientId = this.selectedClient.clientID;
    newProject.projectName = this.submitProjectEntry.get('projectName').value;
    newProject.projectBilledHours = 0;
    newProject.projectEarnings = 0;
    newProject.status = Status.Active;
    newProject.creationDateTime = dayjs().toDate();
    this.store.dispatch(new AddNewProject(newProject));
  }

  public addClient() {
    return this.store.dispatch(new OpenClientAddDialogAction());
  }
}
