import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as access from '../actions/access';
import { GetUserSettingsAction } from '../actions/settings';

@Injectable()
export class AccessEffects {
  submitBillingInfo$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(access.ActionTypes.SUBMITBILLINGINFOSUCCESSACTION),
      map(() => new GetUserSettingsAction())
    ));

  constructor(private action$: Actions) {
  }
}
