@if (data.connectionStatus === SuccessStatus.Success) {
<div class="relative p-24 rounded">
  <h3 class="pt-10 mx-auto font-bold text-center text-18 phone:text-20 max-w-350 text-darkerGray">
    Are you sure you want to disconnect {{data.name}} as your invoicing data source?
  </h3>
  <div class="flex justify-center gap-10 mt-20 mb-10">
    <cartwheel-button class="w-full max-w-150" type="flat-button" color="secondary" label="Yes"
      (onClick)="disConnect()" />
    <cartwheel-button class="w-full max-w-150" type="flat-button" color="lighterGray" label="No"
      (onClick)="closeDialog()" />
  </div>
  <cartwheel-icon-button class="absolute right-10 top-10" matIconName="clear" color="darkGray"
    (onClick)="closeDialog()" />
</div>
} @else {
<div class="p-24 rounded">
  <div class="flex gap-x-10 text-20 text-middleGray">
    <img [src]="data.imageSrc" width="250" class="h-30" />
    Connection
  </div>
  <form class="mt-10 rounded shadow-md p-25" [formGroup]="form" (ngSubmit)="connect()">
    <cartwheel-input label="USERNAME" [inputFormControl]="form.get('username') | formControlType" />
    <cartwheel-input class="mt-10" type="password" label="PASSWORD"
      [inputFormControl]="form.get('password') | formControlType" />
    @if (getIsSageIntacct()) {
      <cartwheel-input class="mt-10" label="SENDER ID" [inputFormControl]="form.get('senderId') | formControlType" />
      <cartwheel-input class="mt-10" type="password" label="SENDER PASSWORD"
        [inputFormControl]="form.get('senderPassword') | formControlType" />
      <cartwheel-input class="mt-10" label="SAGE INTACCT COMPANY ID"
        [inputFormControl]="form.get('sageCompanyId') | formControlType" />
    }
    @if (error$ | async) {
    <div class="mt-5 text-danger text-12">
      {{error$ | async}}
    </div>
    }
    <div class="flex flex-wrap gap-5 mt-15">
      <cartwheel-button submitButton statusButton class="w-full phone:w-130" label="Save" color="secondary"
        [status]="form.invalid ? SuccessStatus.Disabled : loadingStatus" />
      <cartwheel-button class="w-full phone:w-130" label="Cancel" color="middleGray" type="text-button"
        [status]="loadingStatus" (onClick)="closeDialog()" />
    </div>
  </form>
</div>
}
