<div class="relative p-24 rounded">
  <h3 class="pt-10 mx-auto font-bold text-center text-18 phone:text-20 max-w-350 text-darkerGray">
    {{defaultText}}
  </h3>
  <div class="flex justify-center gap-10 mt-20 mb-10">
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="secondary"
      label="Yes"
      (onClick)="changeInvoiceConnectionStatus()"
    />
    <cartwheel-button
      class="w-full max-w-150"
      type="flat-button"
      color="lighterGray"
      label="No"
      (onClick)="closeDialog()"
    />
  </div>
  <cartwheel-icon-button
    class="absolute right-10 top-10"
    matIconName="clear"
    color="darkGray"
    (onClick)="closeDialog()"
  />
</div>