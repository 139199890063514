import { Component, OnInit, Inject, DestroyRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, getUserProfileRoles, getDashboardRole } from 'app/redux/reducers';
import { from, Observable } from 'rxjs';
import { CartwheelIconButtonComponent, CartwheelRoleSelectComponent, UserCompanyRole } from '@cartwheel/web-components';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { UserRole } from 'app/shared/enums';
import { ChangeCurrentRoleAction, GoToDashboardAction } from 'app/redux/actions/dashboard';
import { MatDialogRef } from '@angular/material/dialog';
import { NgForage } from 'ngforage';
import { map, skipWhile } from 'rxjs/operators';
import { CURRENT_ROLE } from 'app/shared/consts';
import { OpenLogOutDialogAction } from 'app/redux/actions/loginuser';

@Component({
  standalone: true,
  selector: 'app-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.scss'],
  imports: [AsyncPipe, CartwheelRoleSelectComponent, CartwheelIconButtonComponent, MatIconModule]
})

export class TeamSelectorComponent implements OnInit {

  public currentRole$: Observable<UserCompanyRole>;
  public roles$: Observable<UserCompanyRole[]>;
  public UserRole = UserRole;

  constructor(
    private destoryRef: DestroyRef,
    @Inject(Store) private store: Store<State>,
    @Inject(NgForage) private lStorage: NgForage,
    @Inject(MatDialogRef) private dialog: MatDialogRef<TeamSelectorComponent>
  ) { }

  ngOnInit(): void {
    this.currentRole$ = this.store.select(getDashboardRole).pipe(skipWhile(role => !role));
    this.roles$ = this.store.select(getUserProfileRoles)
      .pipe(
        takeUntilDestroyed(this.destoryRef),
        map((roles) => roles.filter(r => r.userRole !== UserRole.User || (r.userRole === UserRole.User && r.id === null)))
      );
  }

  changeRole(role: UserCompanyRole): void {
    this.store.dispatch(new ChangeCurrentRoleAction(role));
    from(this.lStorage.setItem(CURRENT_ROLE, JSON.stringify(role)))
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe(() => {
        this.store.dispatch(new GoToDashboardAction());
        this.close();
      })
  }

  public accountLogout() {
    this.close();
    this.store.dispatch(new OpenLogOutDialogAction());

  }

  public close(): void {
    this.dialog.close();
  }
}

interface RoleView extends UserCompanyRole {
  active: boolean;
}
