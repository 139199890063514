import { Injectable, Injector, ErrorHandler, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ErrorService } from './error.service';
import { Store } from '@ngrx/store';
import { State } from 'app/redux/reducers';
import { LogoutAction } from 'app/redux/actions/loginuser';

@Injectable()
export class ErrorInteceptorService implements HttpInterceptor {
  private auth: AuthService;
  private router: Router;
  private errorHandler: ErrorHandler;

  private inj = inject(Injector);
  private store = inject(Store<State>);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(catchError(err => {
      this.auth = this.inj.get(AuthService);
      this.errorHandler = this.inj.get(ErrorHandler);
      this.router = this.inj.get(Router);
      let error: string;
      error = ErrorService.ReturnServerErrorAsString(err);
      if (err.status === 401 || err.status === 403) {
        this.store.dispatch(new LogoutAction(null));
      } else if (err.status >= 400 && err.status !== 401) {
        // there's an error from the server! handle the error using the local method, and
        // make the message go through the normal error handling
        if (err && (err as { ngOriginalError: Error }).ngOriginalError) {
          err = (err as { ngOriginalError: Error }).ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof err === "string" || err instanceof Error) {
          err = error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (err instanceof HttpErrorResponse) {
          // The `error` property of http exception can be either an `Error` object, which we can use directly...
          if (err.error instanceof Error) {
            err = err.error;
          }

          // ... or an`ErrorEvent`, which can provide us with the message but no stack...
          if (err.error instanceof ErrorEvent && err.error.message) {
            err = err.error.message;
          }

          // ...or the request body itself, which we can use as a message instead.
          if (typeof err.error === "string") {
            err = `Server returned code ${err.status} with body "${err.error}"`;
          } else {
            // If we don't have any detailed information, fallback to the request message itself.
            err = err.message;
          }


        }
        this.errorHandler.handleError(err);
        return throwError(() => error);
      } else {
        if (err instanceof HttpErrorResponse) {
          // The `error` property of http exception can be either an `Error` object, which we can use directly...

          if (err.error instanceof Error) {
            err = err.error;
          }

          // ... or an`ErrorEvent`, which can provide us with the message but no stack...
          if (err.error instanceof ErrorEvent && err.error.message) {
            err = err.error.message;
          }

          // ...or the request body itself, which we can use as a message instead.
          if (typeof err.error === "string") {
            err = `Server returned code ${err.status} with body "${err.error}"`;
          } else {
            // If we don't have any detailed information, fallback to the request message itself.
            err = err.message;
          }


        }

        this.errorHandler.handleError(error);
      }

      return throwError(() => err);
    }))
  }

}
