
import { from, Observable, of } from 'rxjs';

import { catchError, map, switchMap, tap, debounceTime, delay } from 'rxjs/operators';
import { LoginFailureAction } from '../actions/loginuser';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../..//services/auth.service';
import { User } from '../../models/user';
import {
  ActionTypes,
  CheckConfirmationAction,
  ConfirmationInvalidAction,
  ConfirmationValidAction,
  LeadSubmitErrorAction,
  LeadSubmitSuccessAction,
  ResendConfirmationAction,
  SubmitGoogleRegistrationAction,
  SubmitGoogleRegistrationErrorAction,
  SubmitGoogleRegistrationSuccessAction,
  SubmitGoogleVerificationCompleteAction,
  SubmitGoogleVerificationErrorAction,
  SubmitGoogleVerificationSuccessAction,
  SubmitLeadAction,
  SubmitRegistrationAction,
  SubmitRegistrationErrorAction,
  SubmitRegistrationSuccessAction,
  DirectToFtuxAction,
  ResetLeadSubmit,
} from '../actions/signup';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ID_TOKEN } from 'app/shared/consts';
import { NgForage } from 'ngforage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';



@Injectable()
export class SignupEffects {
  public createLead$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.SUBMITLEAD),
      switchMap((action: SubmitLeadAction) => {
        return this.authService.captureLead(action.payload).pipe(
          map(res => new LeadSubmitSuccessAction(res)),
          catchError(error => of(new LeadSubmitErrorAction(error))));
      })
    ));

  public resendConfirmationEmail$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.RESENDCONFIRMATION),
      switchMap((action: ResendConfirmationAction) => {
        return this.authService.resendConfirmation(action.payload).pipe(
          map(res => new LeadSubmitSuccessAction(res)),
          catchError(error => of(new LeadSubmitErrorAction(error))));
      })
    ));

  public checkConfirmationCode$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.CHECKCONFIRMATIONCODE),
      switchMap((action: CheckConfirmationAction) => {
        return this.authService.checkConfirmation(action.payload).pipe(
          map(res => new ConfirmationValidAction(res)),
          catchError(error => of(new ConfirmationInvalidAction(error))));
      })
    ));

  public handleSuccessfulConfirmation$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.CONFIRMATIONCODEVALID),
      tap((action) => {
        this.router.navigate(['/Login/Register']);
      })), { dispatch: false });

  public registerUser$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.REGISTRATIONSUBMIT),
      switchMap((action: SubmitRegistrationAction) => {
        return this.authService.registerUser(action.payload).pipe(
          map(res => new SubmitRegistrationSuccessAction(action.payload)),
          catchError(error => of(new SubmitRegistrationErrorAction(error))));
      })
    ));

  public validateGoogleUser$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLEVERIFICATIONSUBMIT),
      switchMap(action => {
        return from(GoogleAuth.signIn()).pipe(
          map(res => new SubmitGoogleVerificationSuccessAction(res.authentication.accessToken)),
          catchError(error => of(new SubmitGoogleVerificationErrorAction(error))));
      })
    ));

  public GooglevalidationValidation$: Observable<Action> = createEffect(() => this.action$.
    pipe(
      ofType(ActionTypes.GOOGLEVERIFICATIONSUCCESSFUL),
      switchMap((action: SubmitGoogleVerificationSuccessAction) => {
        return this.authService.GoogleRegistrationVerification(action.payload).pipe(
          map(result => new SubmitGoogleVerificationCompleteAction(result)),
          catchError(error => of(new SubmitGoogleVerificationErrorAction(error))));
      })
    ));

  public googleValidationComplete$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLEVERIFICATIONCOMPLETE),
      tap(() => {
        this.router.navigate(['/Login/Register']);
      })
    ), { dispatch: false });

  public registerGoogleUser$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLEREGISTRATIONSUBMIT),
      debounceTime(5000),
      switchMap((action: SubmitGoogleRegistrationAction) => {
        return this.authService.registerExternalUser(action.payload).pipe(
          map(result => new SubmitGoogleRegistrationSuccessAction(result)),
          catchError(error => of(new SubmitRegistrationErrorAction(error))));
      })
    ));

  public getNewUserToken$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.REGISTRATIONSUCCESSFUL),
      switchMap((action: SubmitRegistrationSuccessAction) => {
        return this.authService.login(new User(action.payload.email, action.payload.password)).pipe(
          switchMap(res => {
            if (res.access_token) {
              return from(this.lStorage.setItem(ID_TOKEN, res.access_token))
                .pipe(
                  map((successfullySaved: boolean) => new DirectToFtuxAction(successfullySaved)),
                  catchError(error => of(new LoginFailureAction(error)))
                )
            }
          }),
          catchError(err => of(new LoginFailureAction(err))));
      })
    ));

  public loginWithToken$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLEREGISTRATIONSUCCESSFUL),
      switchMap((action: SubmitGoogleRegistrationSuccessAction) => {
        if (action.payload.access_token) {
          return from(this.lStorage.setItem(ID_TOKEN, action.payload.access_token))
            .pipe(
              map((successfullySaved: boolean) => new DirectToFtuxAction(successfullySaved)),
              catchError(error => of(new SubmitGoogleRegistrationErrorAction(error)))
            );
        }
      }),
    ));

  public ResetLeadSubmition: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(
        ActionTypes.LEADSUBMITERROR,
        ActionTypes.LEADSUBMITSUCCESS,
        ActionTypes.SUBMITLEAD,
        ActionTypes.GOOGLEVERIFICATIONERROR,
        ActionTypes.REGISTRATIONERROR,
        ActionTypes.GOOGLEVERIFICATIONCOMPLETE,
        ActionTypes.GOOGLEVERIFICATIONERROR,
        ActionTypes.CONFIRMATIONCODEVALID
      ),
      delay(2500),
      map(() => ResetLeadSubmit())
    )
  )

  public redirectToFTUX: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.DIRECTTOFTUX),
      tap((result: DirectToFtuxAction) => {
        if (result.payload) {
          this.authService.setUserProfile();
          this.router.navigate(['/Home/ftux']);
        }
      })
    ), { dispatch: false });

  constructor(private action$: Actions, private lStorage: NgForage,
    private router: Router, private authService: AuthService) { }
}
