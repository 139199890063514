<div class="dialogBody">
    <div class="topBar">
        <div class="headerItems">
            <span class="headerText">Generate Invoice for "{{selectedProject.projectName}}"</span>
        </div>
        @if ((invoicesLoading$ | async) === false || (invoicesLoading$ | async) !== successStatus.IsLoading) {
            <mat-icon (click)="closeDialog()">clear</mat-icon>
        }
    </div>
    <div class="mat-dialog__content">
        <form class="myForm" [formGroup]="submitFixedRateForm">
            <div class="sections mainInfo">
                <div class="subSection">
                    <label>Project Total</label>
                    <p class="bigHeaderText">{{this.selectedProject.projectTotal |currency:getGlobalSettingsModel.currency}} </p>
                </div>
                <div class="subSection">
                    <label>Amount Paid towards Project</label>
                    <p class="bigHeaderText">{{this.selectedProject.totalPaid ?? 0|currency:getGlobalSettingsModel.currency}} </p>
                </div>
            </div>
            <!-- TODO: add invoice total here -->
            <div class="calulationType sections">
                <label>Invoice Amount or Percentage</label>
                <mat-radio-group formControlName="radioSelection">
                    <div class="mdl-grid">
                        <div class="pr-10 m-0 mdl-cell mdl-cell--6-col">
                            <mat-radio-button class="form-label-value" (change)="updateRadioSelection($event)"
                                color="primary" value="amount">
                                Invoice Amount
                            </mat-radio-button>
                        </div>
                        <div class="pr-10 m-0 mdl-cell mdl-cell--6-col">
                            <mat-radio-button class="form-label-value" (change)="updateRadioSelection($event)"
                                color="primary" value="percentage">
                                Percentage
                            </mat-radio-button>
                        </div>
                    </div>
                </mat-radio-group>
            </div>
            <!-- TODO: add that or section from login here -->
            <div class="selectionSection sections">
                @if (radioSelection === 'percentage') {
                    <div>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Invoice Percentage</mat-label>
                            <input 
                                matInput type="text"
                                [(ngModel)]="selectedPercentage"
                                [ngModelOptions]="{ standalone: true }"
                                (input)="validatePercentageIsValid($event)" />
                            <mat-icon matSuffix>percent</mat-icon>
                        </mat-form-field>
                        @if (!percentageValid) {
                            <p class="errorText">Please ensure the value is a number is between 1 and
                                100</p>
                        }
                    </div>
                }
                @if (radioSelection === 'amount') {
                    <div>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Invoice Amount</mat-label>
                            <input 
                                matInput type="text"
                                [(ngModel)]="selectedAmount"
                                [ngModelOptions]="{ standalone: true }" 
                                (input)="validateAmountIsValid($event)" />
                        </mat-form-field>
                        @if (!amountValid) {
                            <p class="errorText">Please ensure the amount is a number that is less than the
                                project total, and is less than the project total minus total amount paid</p>
                        }
                    </div>
                }
            </div>
            <div class="sections totalSection">
                <label>Invoice Total</label>
                <span class="totalBreakdown">{{invoiceTotal|currency:getGlobalSettingsModel.currency }}</span>
            </div>
            <!-- TODO: Create invoice total breakdown -->
            <div class="buttonSections sections">
                <app-save-button data-cy="generateFixedRateButton"
                    [disabled]="!submitFixedRateForm.valid || (radioSelection === 'percentage' && !percentageValid ) || (radioSelection === 'amount' && !amountValid)"
                    [loading]="invoicesLoading$ | async" [text]="'Generate'" (save)="onSubmit()"></app-save-button>
                @if ((invoicesLoading$ | async) === false) {
                    <span class="cancelTxt"
                        (click)="closeDialog()">Cancel</span>
                }
            </div>
        </form>
    </div>
</div>