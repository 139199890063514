import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { AddUserComponent } from '../shared/dialogs/add-user/add-user.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AddUserRequest, CompanyUser, CompanyUserAndCompanyId, CompanyUserPagedRequest, TeamUserListModel } from 'app/models/CompanyUser';
import { PagedResponse } from 'app/models/pagination';
import { UserInformationDialogComponent } from 'app/team/users/users-components/user-information-dialog/user-information-dialog.component';

@Injectable()
export class UserService {
  private showAddUserDialogRef: MatDialogRef<AddUserComponent>;
  private userInformationDialogRef: MatDialogRef<UserInformationDialogComponent>;
  private baseUrl = environment.server;
  private projectUrl = `${this.baseUrl}/User/Team`;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog
  ) { }

  public showAddUserDialog(filterOptions: CompanyUserPagedRequest): Observable<{}> {
    return new Observable(ob => {
      this.showAddUserDialogRef = this.dialogService.open(AddUserComponent, {
        panelClass: 'cartwheel-base-dialog',
        autoFocus: false,
        disableClose: true,
        data: { filterOptions }
      });
      ob.next('');
    });
  };

  public showUserInformationDialog(payload: { user: TeamUserListModel, filterOptions: CompanyUserPagedRequest }) {
    return new Observable(ob => {
      this.userInformationDialogRef = this.dialogService.open(UserInformationDialogComponent, {
        panelClass: 'cartwheel-base-dialog',
        disableClose: true,
        data: payload
      });
      ob.next('');
    });
  }

  public closeAddUserDialog() {
    this.showAddUserDialogRef?.close();
  }

  public closeUserInformationDialog(): void {
    this.userInformationDialogRef?.close();
  }

  public getCompanyUsers(payload: CompanyUserPagedRequest): Observable<PagedResponse<CompanyUser>> {
    const params = new HttpParams({
      fromObject: new CompanyUserPagedRequest(payload) as Record<string, any>
    });
    return this.http.get<PagedResponse<CompanyUser>>(`${this.baseUrl}/user/Team`, { params });
  }

  public submitAddNewUser(request: AddUserRequest): Observable<CompanyUser> {
    return this.http.post<CompanyUser>(`${this.projectUrl}/AddUser?companyId=${request.user.companyRole.companyId}`, request);
  };

  public deleteCompanyUser(payload: CompanyUser): Observable<string> {
    const url = `${this.baseUrl}/user/Team/DeactivateUser?companyId=${payload.companyRole.companyId}`;
    return this.http.post(url, JSON.stringify(payload), { responseType: 'text' });
  }

  public resendTeamUserConfirmation(payload: CompanyUserAndCompanyId): Observable<string> {
    const url = `${this.baseUrl}/account/ResendInAppConfirmation?companyId=${payload.companyId}`;
    return this.http.post<string>(url, JSON.stringify(payload.companyUser));
  }

  public updateCompanyUser(payload: CompanyUser): Observable<CompanyUser> {
    const url = `${this.baseUrl}/user/Team/UpdateUser?companyId=${payload.companyRole.companyId}`;
    return this.http.post<CompanyUser>(url, JSON.stringify(payload));
  }
}
