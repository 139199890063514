import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpErrorResponse
} from '@angular/common/http';
import { reducers } from './redux/reducers/index';
import { ProjectEffect } from './redux/effects/project';
import { TimerEffects } from './redux/effects/timer';
import { UserEffects } from './redux/effects/user';
import { environment } from '../environments/environment';
import { ClientEffects } from './redux/effects/client';
import { InvoiceEffects } from './redux/effects/invoice';
import { NotificationEffects } from './redux/effects/notifications';
import { ExternalInvoicerEffects } from './redux/effects/external-invoicer';
import { SignupEffects } from './redux/effects/signup';
import { GlobalSettingsEffects } from './redux/effects/settings';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ApplicationRef, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { CartwheelHeaderNotificationComponent, CartwheelIconButtonComponent, CartwheelRoleSelectComponent } from '@cartwheel/web-components';

import { LoginEffects } from './redux/effects/login';
import { ForgotPasswordEffects } from './redux/effects/forgotpassword';

import { AppComponent } from './app.component';
import { CartwheelWebRoutingModule, routes } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './services/auth.service';
import { HomeComponent } from './home/home.component';
// all the services being referenced from the root module, therefore, accessible everywhere
import { ProjectService } from './services/project.service';
import { TimerService } from './services/timer.service';
import { UserService } from './services/user.service';

import { ClientService } from './services/client.service';
import { AuthGuardService } from './services/auth-guard.service';
import { PasswordService } from './services/password.service';
import { WindowService } from './services/window.service';
import { ReportService } from './services/report.service';

import { metaReducers } from './redux/reducers';
import { ExternalInvoiceService } from './services/external-invoice.service';


import { GlobalSettingsService } from './services/global-settings.service';

import { ResponsiveService } from './services/responsive.service';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { ErrorInteceptorService } from './services/error-inteceptor.service';
import { ApproverEffects } from './redux/effects/approver';
import { AccessEffects } from './redux/effects/access';
import { CustomFieldsEffects } from './redux/effects/customfields';
import { InvoiceButtonComponent } from './shared/components/invoice-button/invoice-button.component';
import { BillingService } from './services/billing.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import * as Sentry from '@sentry/angular-ivy';

import { AlertsComponent } from './alerts/alerts.component';
import { DashboardService } from './services/dashboard.service';
import { DashboardEffects } from './redux/effects/dashboard';
import { ErrorService } from './services/error.service';
import { ActiveCompanyInterceptorService } from './services/active-company-interceptor.service';
import { TimesheetEffects } from './redux/effects/timesheet';
import { TimesheetService } from './services/timesheet.service';
import { SeedEffects } from './redux/effects/seed';
import { SeedService } from './services/seed.service';
import { IntegrationService } from './services/integration.service';
import { GraphQLModule } from './graphql.module';
import { NGFORAGE_CONFIG_PROVIDER } from './ngforage.config';
import { NotificationService } from './services/notification.service';
import { ApolloModule } from 'apollo-angular';
import { InvoiceService } from './services/invoice.service';
import { InvoiceDataSourceEffects } from './redux/effects/invoicedatasource';
import { PipesModule } from './pipes/pipes.module';
import { SaveButtonComponent } from './shared/components/save-button/save-button.component';
import { ReportsEffects } from './redux/effects/reports';


export const jwtTokenGetter = (authService: AuthService) => ({
  tokenGetter: () => {
    return authService.getAuthToken();
  }
});

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AlertsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ApolloModule,
    CartwheelWebRoutingModule,
    PipesModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtTokenGetter,
        deps: [AuthService]
      }
    }),
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'disabled' }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      LoginEffects,
      SignupEffects,
      ApproverEffects,
      AccessEffects,
      CustomFieldsEffects,
      SeedEffects,
      ExternalInvoicerEffects,
      ClientEffects,
      InvoiceEffects,
      ForgotPasswordEffects,
      TimerEffects,
      TimesheetEffects,
      UserEffects,
      ProjectEffect,
      GlobalSettingsEffects,
      DashboardEffects,
      NotificationEffects,
      InvoiceDataSourceEffects,
      ReportsEffects
    ]),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: (environment.production || environment.currentEnv === 'staging') }),
    GraphQLModule,
    CartwheelHeaderNotificationComponent,
    CartwheelIconButtonComponent,
    CartwheelRoleSelectComponent,
    SaveButtonComponent,
    InvoiceButtonComponent,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
    MatNativeDateModule
  ],
  providers: [
    IntegrationService,
    AuthService,
    ExternalInvoiceService,
    TimerService,
    UserService,
    ProjectService,
    ClientService,
    WindowService,
    BillingService,
    ReportService,
    InvoiceService,
    SeedService,
    PasswordService,
    ResponsiveService,
    AuthGuardService,
    GlobalSettingsService,
    DashboardService,
    TimesheetService,
    NotificationService,
    ErrorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInteceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveCompanyInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler, useValue: Sentry.createErrorHandler({
        showDialog: false,
        extractor(err, defaultExtractor) {
          if (!environment.production)
            debugger;
          // TODO: if err.stack, it's an error object probably
          const exception = (<any>err).error || (<any>err).message || (<any>err).originalError || err;
          if (exception) {
            return defaultExtractor(err);
          }
          // console.error(test);
          if (
            environment.currentEnv === 'dev' ||
            environment.currentEnv === 'dev-server' ||
            environment.currentEnv === 'local'
          ) {
            console.error((<any>err).originalError || err);
          } else {
            if (!(err instanceof HttpErrorResponse)) {

              // if(!(err instanceof Error)){
              //   err = new Error(err);
              // }
              const eventId = Sentry.captureException((<any>err).originalError || err);
              if (err.toString() === 'Unknown Error') {
                if (environment.currentEnv === 'staging') {
                  Sentry.showReportDialog({ eventId });
                }
              }
            } else {
              // maybe maybe maybe?
              // console.error((<any>err).originalError || err);
            }

          }
        },
      })
    }
    // TODO: remove this(?), and move
    ,
    NGFORAGE_CONFIG_PROVIDER

  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
  constructor(public appRef: ApplicationRef) { }
}
