import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChangePassword } from '../models/registeruser';
@Injectable()
export class PasswordService {

  constructor(private http: HttpClient) { }

  public forgotPassword(email: string): Observable<string> {
    const url = `${environment.server}/account/ForgotPassword`;
    return this.http.post(url, JSON.stringify({ Email: email }), {
      responseType: 'text'
    });
  }

  public changePassword(pw: ChangePassword): Observable<string> {
    const url = `${environment.server}/account/ChangePassword`;
    return this.http.post(url, JSON.stringify(pw), {
      responseType: 'text'
    });
  }

  public changeForgottenPassword(pw: ChangePassword): Observable<string> {
    const url = `${environment.server}/account/ChangeForgottenPassword`;
    return this.http.post(url, JSON.stringify(pw), {
      responseType: 'text'
    });
  }

}
