import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Wagethreshold } from 'app/models/wagethreshold';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  private wagethresholdBaseUrl = `${environment.server}/wagethreshold`;

  constructor( private http: HttpClient ) { }

  getWageThresholdData(companyId: string): Observable<Wagethreshold[]> {
    return this.http.get<Wagethreshold[]>(`${this.wagethresholdBaseUrl}?companyId=${companyId}`);
  }

  saveEditedWageThresholdData(data: Wagethreshold[], companyId: string): Observable<Wagethreshold[]> {
    return this.http.post<Wagethreshold[]>(`${this.wagethresholdBaseUrl}?companyId=${companyId}`, data );
  }

}
