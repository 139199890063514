import { catchError, delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReportsService } from 'app/services/reports.service';
import { getDashboardRole, State } from '../reducers';
import { ReportsAction } from '../actions/reports';

@Injectable()
export class ReportsEffects {
  constructor(
    private action$: Actions,
    private reportsService: ReportsService,
    private store: Store<State>
  ) { }

  getWageThresholdData$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ReportsAction.loadWageThresholdDataAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([, role]) => this.reportsService.getWageThresholdData(role.companyId as string)
      .pipe(
        map(data => ReportsAction.loadWageThresholdDataSuccessAction({ data })),
        catchError(error => of(ReportsAction.loadWageThresholdDataFailureAction(error)))
      )
    )
  ));

  saveWageThresholdData$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(ReportsAction.saveWageThresholdDataAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => this.reportsService.saveEditedWageThresholdData(action.data, role.companyId as string)
      .pipe(
        map(data => ReportsAction.saveWageThresholdDataSuccessAction({ data })),
        catchError(error => of(ReportsAction.saveWageThresholdDataFailureAction(error)))
      )
    )
  ));

  resetWageThresholdStatus$: Observable<Action> = createEffect(() => this.action$.pipe(
    ofType(
      ReportsAction.saveWageThresholdDataFailureAction,
      ReportsAction.saveWageThresholdDataSuccessAction
    ),
    delay(1500),
    map(() => ReportsAction.resetWageThresholdStatusAction())
  ));
}
