<div class="flex justify-end">
  <cartwheel-icon-button matIconName="clear" size="md" color="darkGray" (onClick)="close()" />
</div>

<button disableRipple mat-menu-item matIconSuffix class="mt-10 px-18 min-w-250" (click)="accountLogout()">
  <div class="flex justify-between font-bold">
    <p class="text-15 text-darkerGray leading-22">Log out</p>
    <mat-icon class="text-darkerGray text-[22px] w-22 h-22 absolute right-0">output</mat-icon>
  </div>
</button>
<cartwheel-role-select [roles]="roles$ | async" [role]="currentRole$ | async" (roleChange)="changeRole($event)" />