<div class="relative p-24 rounded">
  <div class="pt-10 mx-auto text-center max-w-350">
      <h3 class="font-bold text-18 phone:text-24 text-darkerGray">
          We hate to see you go...
      </h3>
      <h4 class="text-16 phone:text-20 text-darkGray">
          Please let us know why you are leaving
      </h4>
  </div>
  <cartwheel-textarea class="block mx-20 my-30 phone:mx-30" [rows]="7" [(value)]="message"/>
  <div class="flex justify-center gap-10 my-20">
    <cartwheel-button
      submitButton
      statusButton
      class="w-full max-w-150"
      type="flat-button"
      color="secondary"
      label="Submit"
      [status]="loading$ | async"
      (onClick)="submit()"
    />
  </div>
  <cartwheel-icon-button
    class="absolute right-10 top-10"
    matIconName="clear"
    color="darkGray"
    (onClick)="closeDialog()"
  />
</div>

