<div class="dialog-body">
  <div class="dialog-header">
    <span class="dialog-header-text">Add new Project?</span>
    @if ((projectsLoading$ | async) === false) {
      <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
    }
  </div>
  <div class="dialog-content px-15 tablet:px-75">
    <form class="grid grid-cols-1 gap-20 py-28" [formGroup]="submitProjectEntry">
      <cartwheel-select
        label="CLIENT NAME (REQUIRED)"
        [options]="clientsOptions"
        [selectFormControl]="submitProjectEntry.get('selectedClient') | formControlType"
        addNewType="dialog"
        (onClickAddNew)="addClient()"
      />
      <cartwheel-input
        label="PROJECT NAME (REQUIRED)"
        [inputFormControl]="submitProjectEntry.get('projectName') | formControlType"
      />

      <div
        class="flex items-center gap-5 dialog-footer"
      >
        <cartwheel-button
          class="[&>button]:px-40"
          label="Save"
          color="secondary"
          (onClick)="onSubmit()"
          statusButton
          [status]="
            (!submitProjectEntry.valid && successStatus.Disabled) ||
            (((projectsLoading$ | async) === successStatus.IsLoading || (projectsLoading$ | async) === true) &&
              successStatus.IsLoading)
          "
        />
        @if ((projectsLoading$ | async) === false) {
          <cartwheel-button label="Cancel" type="text-button" color="middleGray" (onClick)="closeDialog()" />
        }
      </div>
    </form>
  </div>
</div>
