import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from 'app/models/notification';
import { environment } from '../../environments/environment';

@Injectable()
export class NotificationService {
  private baseUrl = `${environment.server}/notifications`;
  constructor(private http: HttpClient) { }

  public getNotifications() {
    return this.http.get<Notification[]>(this.baseUrl);
  }

  public updateNotification(n: Notification) {
    return this.http.post<Notification>(`${this.baseUrl}/update`, JSON.stringify(n));
  }
}
