export class CompanyFeedback {
    CommentText?: string;
    constructor(data?: CompanyFeedback ) {
        if (data) {
            if (data.CommentText) {
                this.CommentText = data.CommentText;
            }
        }
    }
}
