import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { CustomFieldEntity, CustomFieldEntryModel, CustomFieldSchemaModel, DeleteCustomFieldEntryModel, DeleteCustomFieldSchemaModel, InsertCustomFieldSchemaModel, UpdateCustomFieldSchemaModel, UpsertCustomFieldEntryModel } from 'app/models/custom-fields';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {
  private customFieldsBaseUrl = `${environment.server}/customfields`;
  private readonly headers: HttpHeaders;
  constructor(
    private http: HttpClient,) {
      this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }
  
  public createCustomFieldSchema(companyId: string, schema: InsertCustomFieldSchemaModel): Observable<CustomFieldSchemaModel> {
    return this.http.post<CustomFieldSchemaModel>(`${this.customFieldsBaseUrl}/schemas`,
      schema, {
        params: { companyId },
        headers: this.headers
      });
  }

  public deleteCustomFieldSchema(companyId: string, schema: DeleteCustomFieldSchemaModel): Observable<boolean> {
    return this.http.delete<boolean>(`${this.customFieldsBaseUrl}/schemas`, {
      body: schema,
      params: { companyId },
      headers: this.headers
    });
  }

  public getCustomFieldSchemas(companyId: string, customFieldEntity?: CustomFieldEntity): Observable<Array<CustomFieldSchemaModel>> {
    const params = customFieldEntity ? { companyId, customFieldEntity } : { companyId };
    return this.http.get<Array<CustomFieldSchemaModel>>(`${this.customFieldsBaseUrl}/schemas`, {
      params,
      headers: this.headers,
    });
  }

  public updateCustomFieldSchema(companyId: string, schema: UpdateCustomFieldSchemaModel): Observable<CustomFieldSchemaModel> {
    return this.http.put<CustomFieldSchemaModel>(`${this.customFieldsBaseUrl}/schemas`, 
      schema, {
        params: { companyId },
        headers: this.headers,
      });
  }

  public getCustomFieldEntries(companyId: string, customFieldEntity: CustomFieldEntity, entityIds?: string[]): Observable<Array<CustomFieldEntryModel>> {
    const params = entityIds ? { companyId, customFieldEntity, entityIds } : { companyId, customFieldEntity };
    return this.http.get<Array<CustomFieldEntryModel>>(`${this.customFieldsBaseUrl}/entries/`, {
      params,
      headers: this.headers,
    });
  }

  public createCustomFieldEntry(companyId: string, entry: UpsertCustomFieldEntryModel): Observable<CustomFieldEntryModel> {
    return this.http.post<CustomFieldEntryModel>(`${this.customFieldsBaseUrl}/entries`, 
      entry, {
        params: { companyId },
        headers: this.headers,
      });
  }

  public updateCustomFieldEntry(companyId: string, entry: UpsertCustomFieldEntryModel): Observable<CustomFieldEntryModel> {
    return this.http.put<CustomFieldEntryModel>(`${this.customFieldsBaseUrl}/entries`,
      entry, {
        params: { companyId },
        headers: this.headers,
      });
  }

  public deleteCustomFieldEntry(companyId: string, entry: DeleteCustomFieldEntryModel): Observable<boolean> {
    return this.http.delete<boolean>(`${this.customFieldsBaseUrl}/entries`, {
      body: entry,
      params: { companyId },
      headers: this.headers,
    });
  }
}