import { Component, OnInit, OnDestroy, Inject, DestroyRef, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CartwheelButtonComponent, CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent, UserCompanyRole } from '@cartwheel/web-components';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { CustomFieldActions } from 'app/redux/actions/customfields';
import { SubmitAddNewUserAction, ClearAddUserErrorAction} from 'app/redux/actions/user';
import { State, getCompanyLoading, getCompanyErrors, getDashboardRole, getGSettingsGlobalSettingsModel, getCustomFieldsSchemas } from 'app/redux/reducers/index';
import { PATTERNS } from 'app/shared/consts';
import { UserRole, UserPosition, UserStatus, SuccessStatus } from 'app/shared/enums';
import { CustomFieldByName, CustomFieldEntity, CustomFieldEntryForm, CustomFieldSchemaModel } from 'app/models/custom-fields';
import { CompanyUserPagedRequest } from 'app/models/CompanyUser';
import { AddUserRequest } from 'app/models/CompanyUser';
import { EnterpriseUser } from 'app/models/enterprise-user';
import { PipesModule } from 'app/pipes/pipes.module';

@Component({
  standalone: true,
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  imports: [
    AsyncPipe, KeyValuePipe, PipesModule, ReactiveFormsModule, CartwheelButtonComponent,
    CartwheelIconButtonComponent, CartwheelInputComponent, CartwheelSelectComponent, MatCheckboxModule
  ]
})
export class AddUserComponent implements OnInit, OnDestroy {
  getCustomUserFieldsSchemas$: Observable<CustomFieldSchemaModel[]>;
  getCustomUserFieldsSchemas = [];

  customUserFields: FormArray<FormGroup<CustomFieldEntryForm>> = new FormArray<FormGroup<CustomFieldEntryForm>>([]);

  UserPosition = UserPosition;
  userPosition = UserPosition.Employee;
  submitAddUser: UntypedFormGroup;
  submitting: false;
  loading$: Observable<boolean | SuccessStatus>;
  error$: Observable<string>;
  userIsInvoicedSeparately$: Observable<boolean>;
  userRole = UserRole;
  successStatus = SuccessStatus;
  private currentRole$: Observable<UserCompanyRole>;
  private currentRole: UserCompanyRole;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CompanyUserPagedRequest,
    private dialog: MatDialogRef<AddUserComponent>,
    private store: Store<State>,
    private destroyRef: DestroyRef
  ) { }

  ngOnInit(): void {
    this.userPosition = UserPosition.Employee;
    this.loading$ = this.store.select(getCompanyLoading);
    this.error$ = this.store.select(getCompanyErrors);
    this.currentRole$ = this.store.select(getDashboardRole);
    this.userIsInvoicedSeparately$ = this.store.select(getGSettingsGlobalSettingsModel)
      .pipe(map(s => s.companySettings?.userIsInvoicedSeparately));

    this.currentRole$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((role) => { this.currentRole = role });

    this.submitAddUser = new UntypedFormGroup({
      userPosition: new UntypedFormControl(UserPosition.Employee),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.email),
      hourlyRate: new UntypedFormControl(null, [Validators.required, Validators.pattern(PATTERNS.DecimalWithMax9999)]),
      otHourlyRate: new UntypedFormControl(null, Validators.pattern(PATTERNS.DecimalWithMax9999)),
      permissions: new UntypedFormControl(UserRole.Admin),
      companyRole: new UntypedFormGroup({
        userCompanySettings: new UntypedFormGroup({
          generateInvoicesSeparately: new UntypedFormControl(false),
        })
      })
    });
    
    this.store.dispatch(CustomFieldActions.getCustomFieldSchemasByEntity({ entity: CustomFieldEntity.User }));
    this.getCustomUserFieldsSchemas$ = this.store.select(getCustomFieldsSchemas);
    this.getCustomUserFieldsSchemas$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((schemas: CustomFieldSchemaModel[]) => {
        this.getCustomUserFieldsSchemas = schemas.filter(schema => schema.customFieldEntity === CustomFieldEntity.User);
        this.customUserFields = this._initCustomFields(this.getCustomUserFieldsSchemas);
      });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearAddUserErrorAction())
    
  }

  save(): void {
    const submitVals = this.submitAddUser.value;
    if (submitVals.userPosition === UserPosition.Contractor) {
      submitVals.permissions = UserRole.User;
    }
    submitVals.status = UserStatus.Unconfirmed;
    const companyRole = new UserCompanyRole();
    companyRole.userRole = submitVals.permissions;
    companyRole.companyId = this.currentRole.companyId;
    companyRole.hourlyRate = submitVals.hourlyRate || 0;
    companyRole.otHourlyRate = submitVals.otHourlyRate || 0;
    companyRole.userCompanySettings = submitVals.companyRole.userCompanySettings;
    const newUserValue = new EnterpriseUser(submitVals);
    newUserValue.companyRole = companyRole;

    const newCustomFields = this.getNewFields();
    const addUserRequest: AddUserRequest = {
      user: newUserValue,
      ...(newCustomFields.length && { customFields: newCustomFields })
    };

    this.store.dispatch(new SubmitAddNewUserAction({addUserRequest, filterOptions: this.data}));

    this.submitAddUser.markAsPristine();
  }

  closeDialog(): void {
    this.dialog.close();
  }
  
  getNewFields(): CustomFieldByName[] {
    const schemaMap = new Map(this.getCustomUserFieldsSchemas.map(schema => [schema.fieldName, schema]));
  
    return this.customUserFields.controls.reduce<CustomFieldByName[]>((newFields, formGroup) => {
      Object.entries(formGroup.controls).forEach(([fieldName, current]) => {
        if (schemaMap.get(fieldName)?.defaultFieldValue !== current.value) {
          newFields.push({
            customFieldEntity: CustomFieldEntity.User,
            fieldName,
            fieldValue: current.value,
          });
        }
      });
  
      return newFields;
    }, []);
  }

  private _initCustomFields(schemas: CustomFieldSchemaModel[]): FormArray<FormGroup<CustomFieldEntryForm>> {
    const customFields = schemas.map(schema =>
      new FormGroup<CustomFieldEntryForm>({
        [schema.fieldName]: new FormControl<string | null>(schema.defaultFieldValue || null)
      })
    );
  
    return new FormArray<FormGroup<CustomFieldEntryForm>>(customFields);
  }
}
