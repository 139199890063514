
import { of as observableOf, Observable, of, from } from 'rxjs';

import { catchError, map, switchMap, tap, mergeMap, withLatestFrom, delay } from 'rxjs/operators';
import {
  GoogleLoginCompleteAction,
  GoogleLoginSuccessAction,
  LoginAction,
  LoginFailureAction,
  LoginSuccessAction, LogoutFailure, LogoutSuccess, OpenLogOutDialogAction, OpenLogoutDialogFailedAction,
  OpenLogoutDialogSuccessfulAction,
  GetUserRolesAction,
  GetUserRolesSuccessfulAction,
  GetUserRolesFailedAction,
  SetUserViewRoleAction,
  LoggedInAction,
  ResetSettingsAction, SubmitChangeForgottenPasswordAction, SubmitChangeForgottenPasswordSuccessAction
} from '../actions/loginuser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {
  ActionTypes, SubmitChangePasswordAction, SubmitChangePasswordErrorAction,
  SubmitChangePasswordSuccessAction
} from '../actions/loginuser';
import { PasswordService } from '../../services/password.service';
import { ChangeCurrentRoleAction } from '../actions/dashboard';
import { CURRENT_ROLE, ID_TOKEN } from 'app/shared/consts';
import { getDashboardRole, State } from '../reducers';
import { UserCompanyRole } from '@cartwheel/web-components';
import { NgForage } from 'ngforage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { UserRole } from 'app/shared/enums';

@Injectable()
export class LoginEffects {

  public login$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOGIN),
      switchMap((action: LoginAction) => {
        return this.authService.login(action.payload).pipe(
          map((res) => {
            if (res.ok === false) {
              return new LoginFailureAction(res);
            } else {
              return new LoginSuccessAction(res);
            }
          }),
          catchError(error => observableOf(new LoginFailureAction(error))));
      })
    ));

  googleLoginCompleteAction$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLELOGINCOMPLETE),
      switchMap((result: GoogleLoginCompleteAction) => {
        if (result.payload && result.payload.access_token) {
          return from(this.lStorage.setItem(ID_TOKEN, result.payload.access_token))
            .pipe(
              map(() => new LoggedInAction(true)),
            );
        }
      })
    ));

  emailLoginComplete$: Observable<Action> = createEffect(() => this.action$
    .pipe(ofType(ActionTypes.LOGINSUCCESS),
      switchMap((result: LoginSuccessAction) => {
        if (result.payload && result.payload.access_token) {
          return from(this.lStorage.setItem(ID_TOKEN, result.payload.access_token))
            .pipe(
              map(() => new LoggedInAction(true)),
            );
        }
      })
    ));

  loginRedirect$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOGGEDIN),
      tap((action: LoggedInAction) => {
        if (action.payload) {
          this.authService.setUserProfile();
        }
      })
    ), { dispatch: false });

  googlelogin$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLELOGIN),
      switchMap(action => {
        return from(GoogleAuth.signIn()).pipe(
          map(res => new GoogleLoginSuccessAction(res)),
          catchError(error => observableOf(new LoginFailureAction(error))));
      })
    ));

  openLogOutDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENLOGOUTDIALOG),
      switchMap((action: OpenLogOutDialogAction) => {
        return this.authService.showLogOutDialog().pipe(
          map(res => new OpenLogoutDialogSuccessfulAction(action.payload)),
          catchError(err => observableOf(new OpenLogoutDialogFailedAction(err))));
      })
    ));

  logout$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.LOGOUT),
      switchMap(() => this.authService.logout().pipe(
        catchError(err => of(new LogoutFailure(err)))
      )),
      switchMap(() => this._router.navigate(['/Login'])),
      map(() => new LogoutSuccess())
    ));

  getUserRoles$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERROLES),
      switchMap((result: GetUserRolesAction) => {
        return this.authService.getUserRoles().pipe(
          map(res => new GetUserRolesSuccessfulAction(res)),
          catchError(error => observableOf(new GetUserRolesFailedAction(error.error))));
      })
    ));

  getUserRolesSuccess$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GETUSERROLESSUCCESSFUL),
      withLatestFrom(this.store.select(getDashboardRole)),
      mergeMap(([result, currentRole]: [GetUserRolesSuccessfulAction, UserCompanyRole]) => {

        // Get highest user role
        const highestUserRole = result.payload.find(ucr => ucr.userRole === Math.min(...result.payload.map(s => s.userRole)));

        if (!currentRole) {
          this._router.navigate([ highestUserRole.userRole === UserRole.User ? '/Home' : '/Team']);
          return from(this.lStorage.setItem(CURRENT_ROLE, JSON.stringify(highestUserRole))).pipe(
            switchMap(success => [
              new SetUserViewRoleAction(highestUserRole),
              new ChangeCurrentRoleAction(highestUserRole)
            ])
          );
        } else {
          const roleExists = result.payload
            .find(ucr => currentRole.userRole === ucr.userRole && currentRole.companyId === ucr.companyId)

          if (!roleExists) {
            return from(this.lStorage.setItem(CURRENT_ROLE, JSON.stringify(highestUserRole))).pipe(
              switchMap(success => {
                this._router.navigate(['/Home']);
                return [
                  new SetUserViewRoleAction(highestUserRole),
                  new ChangeCurrentRoleAction(highestUserRole)
                ];
              })
            );

          } else {
            return [new SetUserViewRoleAction(highestUserRole)];
          }
        }
      })
    ));

  googleloginsuccess$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.GOOGLELOGINSUCCESS),
      switchMap((result: GoogleLoginSuccessAction) => {
        return this.authService.GoogleVerify(result.payload.authentication.accessToken).pipe(
          map(res => new GoogleLoginCompleteAction(res)),
          catchError(error => observableOf(new LoginFailureAction(error))));
      })
    ));

  forgotPassword$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.CHANGEFORGOTTENPASSWORD),
      switchMap((action: SubmitChangeForgottenPasswordAction) => {
        return this.passwordService.changeForgottenPassword(action.payload).pipe(
          map(result => new SubmitChangeForgottenPasswordSuccessAction(result)),
          catchError(error => observableOf(new SubmitChangePasswordErrorAction(error))));
      })
    ));

  changePasswordUser$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.CHANGEPASSWORDSUBMIT),
      switchMap((action: SubmitChangePasswordAction) => {
        return this.passwordService.changePassword(action.payload).pipe(
          map(result => new SubmitChangePasswordSuccessAction(result)),
          catchError(error => observableOf(new SubmitChangePasswordErrorAction(error))));
      })
    ));

  resetLoginSettings$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(
        ActionTypes.CHANGEPASSWORDSUBMITSUCCESSFUL,
        ActionTypes.CHANGEPASSWORDSUBMITERROR,
        ActionTypes.LOGINFAILURE,
        ActionTypes.LOGINSUCCESS,
        ActionTypes.GETUSERROLESSUCCESSFUL
      ),
      delay(2500),
      map(() => new ResetSettingsAction())
    ));

  constructor(
    private action$: Actions,
    private authService: AuthService,
    private passwordService: PasswordService,
    private store: Store<State>,
    private lStorage: NgForage,
    private _router: Router
  ) { }
}
