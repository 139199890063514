
import { debounceTime, switchMap, catchError, map, tap } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';
import { of, Observable } from 'rxjs';
import {
  ActionTypes,
  LoadProjectsFromServerFailedAction,
  LoadProjectsFromServerSuccessfulAction,
  AddNewProject,
  AddNewProjectSuccessful,
  AddNewProjectFailed,
  EditProjectAction,
  EditProjectSuccessful,
  EditNewProjectFailed,
  OpenProjectDialogSuccessfulAction,
  OpenProjectDialogFailedAction,
  OpenProjectDialogAction,
  HideIconStatusSuccessful,
  BatchAddProjectAction,
  BatchAddProjectSuccesfulAction,
  BatchAddProjectFailureAction,
  ResetProjectLoadingAction,
  BatchUpdateProjectAction,
  BatchUpdateProjectSuccesfulAction,
  BatchUpdateProjectFailureAction,
  LinkProjectToExternalApplicationAction,
  LoadEnterpriseProjectsFromServerAction,
  LoadEnterpriseProjectsFromServerSuccessfulAction,
  LoadEnterpriseProjectsFromServerFailedAction,
  OpenAddTeamDialogAction,
  OpenAddTeamDialogSuccessAction
} from '../actions/project';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Project } from '../../models/project';
import { ExternalInvoiceService } from '../../services/external-invoice.service';
import { LoadProjectsFromProviderFailureAction } from '../actions/approver';
import { ClientService } from 'app/services/client.service';
import { ApprovalType } from 'app/shared/enums';
import { Client } from 'app/models/Client';

@Injectable()
export class ProjectEffect {

  // write effects to get data from local DB and server
  loadProjectsFromServer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      // trace('loadProjectsFromServer:Effect'),
      ofType(ActionTypes.LOADPROJECTSFROMSERVER),
      switchMap(action => {
        return this.projectService
          .GetAllProjectsFromServer().pipe(
            map((success: Project[]) => new LoadProjectsFromServerSuccessfulAction(success.map(e => new Project(e)))),
            catchError(err => of(new LoadProjectsFromServerFailedAction(err))));
      })));

  loadAdminProjectsFromServer$ = createEffect(() => this.action$.pipe(
    ofType(LoadEnterpriseProjectsFromServerAction),
    switchMap(() => this.projectService.GetAllEnterpriseProjectsFromServer().pipe(
      map((success: Project[]) => new LoadEnterpriseProjectsFromServerSuccessfulAction(success),
        catchError(err => of(LoadEnterpriseProjectsFromServerFailedAction({ error: err }))))
    ))
  ));

  saveProjectToServer$ = createEffect(() => this.action$.pipe(
    ofType(ActionTypes.ADDNEWPROJECT),
    switchMap((action: AddNewProject) =>
      this.projectService
        .AddProject(action.payload).pipe(
          map((res: Project) => new LinkProjectToExternalApplicationAction(res)),
          catchError(err => of(new AddNewProjectFailed(err))))
    )));

  linkProjectToApplication$ = createEffect(() => this.action$
    .pipe(
      ofType<LinkProjectToExternalApplicationAction>(ActionTypes.LINKPROJECTTOEXTERNALAPPLICATION),
      switchMap((action: LinkProjectToExternalApplicationAction) => this.clientService.getSelectedClient().pipe(
        switchMap((client: Client) => {
          const approver = client.approvers
            .filter((a) => a.approvalType === ApprovalType.Application)
            .shift();

          if (!approver) {
            return of(new AddNewProjectSuccessful());
          }

          return this.externalService.loadClientsFromProvider(approver.appType)
            .pipe(
              switchMap((clients: Client[]) => this.externalService.showClientChoiceDialog(approver.appType, clients, action.payload)),
              catchError(err => of(new LoadProjectsFromProviderFailureAction(err))
              ));
        })
      ))));

  saveProjectToServerBatch$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.BATCHADDPROJECT),
      switchMap((action: BatchAddProjectAction) => {
        return this.projectService.BatchAddProject(action.payload).pipe(
          map(res => new BatchAddProjectSuccesfulAction()),
          catchError(err => of(new BatchAddProjectFailureAction(err))))
      })
    ));

  updateProjectsBatch$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.BATCHUPDATEPROJECT),
      switchMap((action: BatchUpdateProjectAction) => {
        return this.projectService.BatchUpdateProject(action.payload).pipe(
          map(res => new BatchUpdateProjectSuccesfulAction()),
          catchError(err => of(new BatchUpdateProjectFailureAction(err))))
      })
    ));

  resetBatchProjectStatus$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.BATCHADDPROJECTSUCCESSFUL, ActionTypes.BATCHADDPROJECTFAILED),
      debounceTime(2000),
      map(() => { this.externalService.closeClientChoiceDialog(); })
    ), { dispatch: false });

  closeProjectDialog$ = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.ADDNEWPROJECTSUCCESSFUL),
      debounceTime(1200),
      tap(() => {
        this.projectService.closeAddProjectDialog();
        this.projectService.closeAddTeamProjectDialog();
      }),
      debounceTime(300), // Stops slight flicker of button as dialog closes
      map(() => new ResetProjectLoadingAction())));

  resetProjectLoadingStatus = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.ADDNEWPROJECTFAILED),
      debounceTime(1200),
      map(() => new ResetProjectLoadingAction())));

  openProjectTimerDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENADDPROJECTDIALOG),
      switchMap((action: OpenProjectDialogAction) => {
        return this.projectService
          .addProjectDialog().pipe(
            map(res => OpenAddTeamDialogSuccessAction()),
            catchError(err => of(new OpenProjectDialogFailedAction(err))));
      })));

  openAddTeamProjectDialog$ = createEffect(() => this.action$.pipe(
    ofType(OpenAddTeamDialogAction),
    switchMap((action) => {
      return this.projectService.addTeamProjectDialog().pipe(
        map(res => new OpenProjectDialogSuccessfulAction(null)),
        catchError(err => of(new OpenProjectDialogFailedAction(err)))
      );
    })
  ));

  editProjectOnServer$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EDITPROJECT),
      debounceTime(3000),
      switchMap((action: EditProjectAction) => {
        return this.projectService
          .EditProject(action.payload).pipe(
            map(result => new EditProjectSuccessful(action.payload)),
            catchError(err => of(new EditNewProjectFailed(action.payload))));
      })));

  showSuccessNetworkStatusIcon: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EDITPROJECTSUCCESSFUL),
      debounceTime(3000),
      map((action: EditProjectSuccessful) => new HideIconStatusSuccessful(action.payload))));

  showDangerNetworkStatusIcon: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.EDITPROJECTFAILED),
      debounceTime(3000),
      map((action: EditNewProjectFailed) => new HideIconStatusSuccessful(action.payload))));

  constructor(private action$: Actions, private projectService: ProjectService,
    private externalService: ExternalInvoiceService, private clientService: ClientService) { }
}
