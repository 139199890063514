import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogModule } from '@angular/material/dialog';
import { State } from '../../../redux/reducers';
import { ShowReactivateAccountDialogAction } from 'app/redux/actions/settings';

@Component({
  standalone: true,
  selector: 'app-disable-access-component',
  templateUrl: './disable-access.component.html',
  styleUrls: ['./disable-access.component.scss'],
  imports: [MatDialogModule]
})

export class DisableAccessComponent {

  constructor(private store: Store<State>) { }

  startSubscriptionButton() {
    // Todo add toggle and billing info
    this.store.dispatch(new ShowReactivateAccountDialogAction());
  }

}
