import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ActionTypes, GoToDashboardAction,
} from '../actions/dashboard';

import { DashboardService } from '../../services/dashboard.service';
import { tap, map } from 'rxjs/operators';
import { State, getDashboardRole } from '../reducers';
import { combineLatest } from 'rxjs';
import { UserCompanyRole } from '@cartwheel/web-components';
import { UserRole } from 'app/shared/enums';
import { Router } from '@angular/router';

@Injectable()
export class DashboardEffects {

  openTeamSelectorDialog$: Observable<Action> = createEffect(() => this.action$
    .pipe(
      ofType(ActionTypes.OPENTEAMSELECTOR),
      tap(() => this.dashboardService.showTeamSelectorDialog())
    ), { dispatch: false });

  goToDashboard$: Observable<string[]> = createEffect(() => {
    return combineLatest([this.action$.pipe(ofType(ActionTypes.GOTODASHBOARD)),
      this.store.select(getDashboardRole)]
      ).pipe(
        map(([action, role]: [GoToDashboardAction, UserCompanyRole]) => {
          if (role) {
            const base = (role.userRole === UserRole.User) ? ['/Home'] : ['/Team'];
            return base.concat(action.payload);
          }
          return ['/Home'];
        }),
        tap(route => this.router.navigate(route))
      )
  }, { dispatch: false });

  constructor(
    private action$: Actions,
    private store: Store<State>,
    private router: Router,
    private dashboardService: DashboardService
  ) { }
}
