<div *ngIf="(alerts$ | async).length > 0" class="alert-container">
  <div class="alert" *ngFor="let alert of (alerts$ | async); let i = index;">
    <img src="assets/icons/alert.svg">
    <ng-container *ngIf="alert.type === AlertType.ApplicationConnection; else missingEmail">
      <ng-container *ngIf="alert.applicationType !== null && alert.applicationType !== undefined; else invoiceProvider">
        <span>
          Your client <strong>{{ alert.name }}</strong> needs you to connect your
          {{ ApplicationTypes[alert.applicationType] }} account.
        </span>
        <button mat-button (click)="handleClick(alert.applicationType, i)"
          [class.loading]="(loadingStatus$ | async)[alert.applicationType] === NetworkConnectionStatus.IsLoading && i === currentAlert"
          [class.unauthenticated]="(loadingStatus$ | async)[alert.applicationType] === NetworkConnectionStatus.Error && i === currentAlert"
          [class.clicked]="(authStatus$ | async)[alert.applicationType] && (loadingStatus$ | async)[alert.applicationType] !== NetworkConnectionStatus.IsLoading && i === currentAlert"
          [class.inactive]="(loadingStatus$ | async)[alert.applicationType] === NetworkConnectionStatus.Disabled && i === currentAlert">
          <div
            [class.cross]="(loadingStatus$ | async)[alert.applicationType] === NetworkConnectionStatus.Error && i === currentAlert"
            [class.checkmark]="(authStatus$ | async)[alert.applicationType] && (loadingStatus$ | async)[alert.applicationType] !== NetworkConnectionStatus.IsLoading && i === currentAlert">
            <img [src]="applicationImages[alert.applicationType]" />
          </div>
          <mat-spinner
            *ngIf="(loadingStatus$ | async)[alert.applicationType] === NetworkConnectionStatus.IsLoading && i === currentAlert"
            single-color strokeWidth="3" diameter="25">
          </mat-spinner>
        </button>
      </ng-container>
      <ng-template #invoiceProvider>
        <span>
          The company <strong>{{ alert.name }}</strong> needs you to connect your
          {{ ExternalInvoicingSystems[alert.providerType] }} account.
        </span>
        <app-invoice-button [disabled]="(providerState$ | async)[alert.providerType]?.connectionStatus !== false"
          [invoiceProvider]="providerData[alert.providerType]"
          [status]="(providerState$ | async)[alert.providerType]?.connectionStatus">
        </app-invoice-button>
      </ng-template>
    </ng-container>
    <ng-template #missingEmail></ng-template>
  </div>
</div>
