import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Apollo, gql, Subscription } from 'apollo-angular';
import { Notification } from 'app/models/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationReceived extends Subscription<NotificationReceivedEventResponse> {
  document;

  constructor(apollo: Apollo, userId: UUID) {
    super(apollo);
    this.document = gql`
      subscription {
        notificationReceived(userId: "${userId}") {
          notificationId
          message
          userId
          type
          status
          createdAt
        }
      }
    `
  }
}

export interface NotificationReceivedEventResponse {
  notificationReceived: Pick<Notification, 'createdAt' | 'notificationId' | 'message' | 'userId' | 'type' | 'status'>
}

