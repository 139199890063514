import { UserCompanyRole } from '@cartwheel/web-components';
import { UserStatus } from 'app/shared/enums';

export class EnterpriseUser {

  firstName: string;
  lastName: string;
  email: string;
  companyRole: UserCompanyRole;
  companyId: string;
  status: UserStatus;

  constructor(data?: any) {
    if (data) {
      if (data.firstName) {
        this.firstName = data.firstName;
      }
      if (data.lastName) {
        this.lastName = data.lastName;
      }
      if (data.email) {
        this.email = data.email;
      }
      if (data.companyRole) {
        this.companyRole = data.companyRole;
      }
      if (data.companyId) {
        this.companyId = data.companyId;
      }
      if (data.status !== null && data.status !== undefined) {
        this.status = data.status;
      }
    }
  }
}
