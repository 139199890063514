import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, debounceTime, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { State, getDashboardRole } from "../reducers";
import { InvoiceDataSourceService } from "app/services/invoice-data-source.service";
import { InvoiceDataSourceActions } from "../actions";
import { ExternalInvoicingSystems } from "app/shared/enums";
import { GenerateUserTokenSuccessfulAction } from "../actions/ftux";

@Injectable()
export class InvoiceDataSourceEffects {

  openInvoiceDialog$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.openInvoiceDataSourceDialogAction),
    switchMap((action) => this.invoiceDataSourceService.showInvoiceDataSourceDialog(action.invoiceDataSource).pipe(
      map(() => InvoiceDataSourceActions.openInvoiceDataSourceDialogSuccessAction({ invoiceDataSource: action.invoiceDataSource })),
      catchError(() => of(InvoiceDataSourceActions.openInvoiceDataSourceDialogFailureAction()))
    ))
  ));

  invoiceDataSourceLogin$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.invoiceDataSourceLoginAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) =>
      this.invoiceDataSourceService.connnectInvoiceDataSource({ ...action.invoiceDataSourceAuthData, UserRole: role }).pipe(
        map(value =>
          InvoiceDataSourceActions.invoiceDataSourceLoginSuccessAction({
            invoiceDataSourceLoginResult: {
              dataSource: ExternalInvoicingSystems[action.invoiceDataSourceAuthData.InvoicingSystems],
              result: value
            }
          })),
        catchError(error =>
          of(InvoiceDataSourceActions.invoiceDataSourceLoginFailureAction({
            invoiceDataSourceLoginResult: {
              dataSource: ExternalInvoicingSystems[action.invoiceDataSourceAuthData.InvoicingSystems],
              error: error
            }
          })))
      ))
  ));

  sageIntacctDataSourceLogin$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.sageIntacctDataSourceLoginAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) =>
      this.invoiceDataSourceService.connectSageIntacctDatasource({ ...action.sageIntacctDataSourceAuthData, companyId: role.companyId }).pipe(
        map(value =>
          InvoiceDataSourceActions.invoiceDataSourceLoginSuccessAction({
            invoiceDataSourceLoginResult: {
              dataSource: ExternalInvoicingSystems[ExternalInvoicingSystems.SageIntacct],
              result: value
            }
          })),
        catchError(error =>
          of(InvoiceDataSourceActions.invoiceDataSourceLoginFailureAction({
            invoiceDataSourceLoginResult: {
              dataSource: ExternalInvoicingSystems[ExternalInvoicingSystems.SageIntacct],
              error: error
            }
          })))
      ))
  ));

  invoiceDataSourceLoginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.invoiceDataSourceLoginSuccessAction),
    debounceTime(1000),
    mergeMap(action => {
      const list = [
        InvoiceDataSourceActions.invoiceDataSourceLoginCompleteAction({
          invoiceDataSourceDialog: {
            dataSource: action.invoiceDataSourceLoginResult.dataSource,
            closeDialog: true
          }
        }),
        new GenerateUserTokenSuccessfulAction({ provider: action.invoiceDataSourceLoginResult.dataSource, result: true })
      ]
      if (action.invoiceDataSourceLoginResult.dataSource !== ExternalInvoicingSystems[ExternalInvoicingSystems.SageIntacct]) {
        list.splice(1, 1);
      }
      return list;
    })
  ));

  invoiceDataSourceLoginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.invoiceDataSourceLoginFailureAction),
    debounceTime(2500),
    map(action => {
      return InvoiceDataSourceActions.invoiceDataSourceLoginCompleteAction({
        invoiceDataSourceDialog: {
          dataSource: action.invoiceDataSourceLoginResult.dataSource,
          closeDialog: false
        }
      })
    })
  ));

  invoiceDataSourceLoginComplete$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.invoiceDataSourceLoginCompleteAction),
    switchMap((action) => {
      return this.invoiceDataSourceService.closeInvoiceDataSourceDialog(action.invoiceDataSourceDialog.closeDialog);
    })
  ), { dispatch: false });

  disconnectInvoiceDataSource$ = createEffect(() => this.actions$.pipe(
    ofType(InvoiceDataSourceActions.disconnectInvoiceDataSourceAction),
    withLatestFrom(this.store.select(getDashboardRole)),
    switchMap(([action, role]) => {
      return this.invoiceDataSourceService.disconnectInvoiceDataSource({ userRole: role, invoicingSystems: action.invoicingSystems }, role.companyId).pipe(
        map(() => InvoiceDataSourceActions.disconnectInvoiceDataSourceSuccessAction({ invoicingSystems: action.invoicingSystems })),
        catchError(() => of(InvoiceDataSourceActions.disconnectInvoiceDataSourceFailureAction({ invoicingSystems: action.invoicingSystems }))));
    }))
  );

  disconnectInvoiceDataSourceFinished$ = createEffect(() => this.actions$.pipe(
    ofType(
      InvoiceDataSourceActions.disconnectInvoiceDataSourceSuccessAction,
      InvoiceDataSourceActions.disconnectInvoiceDataSourceFailureAction
    ),
    switchMap(() => {
      return this.invoiceDataSourceService.closeInvoiceDataSourceDialog(true);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private invoiceDataSourceService: InvoiceDataSourceService,
    private store: Store<State>,
  ) { }
}

