import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  State,
  getAlerts,
  getApproverComplete,
  getApproverAuthStatus,
  getApproverLoadingStatus,
  getFtuxProvidersState,
  getFtuxLoginComplete
} from 'app/redux/reducers';
import { Alert } from 'app/models/alerts';
import { Observable, Subject } from 'rxjs';
import { AlertType, ApplicationTypes, SuccessStatus, ExternalInvoicingSystems } from 'app/shared/enums';
import { ApplicationAuthStatus, ApplicationLoadingStatus } from '../models/approver';
import { HarvestLoginAction, TogglOpenDialogAction } from 'app/redux/actions/approver';
import { take, delay, withLatestFrom } from 'rxjs/operators';
import { RemoveAlertAction, SetAlertFlagAction } from 'app/redux/actions/alerts';
import { ProviderState } from 'app/models/external-invoice';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit {
  alerts$: Observable<Alert[]>;
  alerts: Alert[];
  AlertType = AlertType;
  ApplicationTypes = ApplicationTypes;
  ExternalInvoicingSystems = ExternalInvoicingSystems;
  NetworkConnectionStatus = SuccessStatus;

  public authStatus$: Observable<ApplicationAuthStatus>;
  public loadingStatus$: Observable<ApplicationLoadingStatus>;
  public loadingStatus: ApplicationLoadingStatus;
  public providerState$: Observable<ProviderState>;
  public currentAlert: number;

  applicationImages = {
    [ApplicationTypes.Harvest]: 'assets/harvest.png',
    [ApplicationTypes.ADP]: 'assets/adp.png',
    [ApplicationTypes.Toggl]: 'assets/toggl-nobackground.png'
  }

  providerData = {
    // [ExternalInvoicingSystems.Veem]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Veem],
    //   imageSrc: 'assets/veem-logo.png'
    // },
    [ExternalInvoicingSystems.QuickBooks]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.QuickBooks],
      imageSrc: 'assets/quick-books-logo.png'
    }
    ,
    [ExternalInvoicingSystems.FreshBooks]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.FreshBooks],
      imageSrc: 'assets/fresh-books-logo.png'
    },
    [ExternalInvoicingSystems.Xero]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.Xero],
      imageSrc: 'assets/xero-logo.png'
    },
    [ExternalInvoicingSystems.Qwil]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.Qwil],
      imageSrc: 'assets/qwil-logo.svg'
    },
    [ExternalInvoicingSystems.BillCom]: {
      name: ExternalInvoicingSystems[ExternalInvoicingSystems.BillCom],
      imageSrc: 'assets/billcom.svg'
    }
    // ,
    // [ExternalInvoicingSystems.FreshBooks]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.FreshBooks],
    //   imageSrc: 'assets/fresh-books-logo.png'
    // },
    // [ExternalInvoicingSystems.Xero]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Xero],
    //   imageSrc: 'assets/xero-logo.png'
    // },
    // [ExternalInvoicingSystems.Qwil]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.Qwil],
    //   imageSrc: 'assets/qwil-logo.svg'
    // },
    // [ExternalInvoicingSystems.BillCom]: {
    //   name: ExternalInvoicingSystems[ExternalInvoicingSystems.BillCom],
    //   imageSrc: 'assets/billcom.svg'
    // }
  }

  constructor(private store: Store<State>, private destroyRef: DestroyRef) { }

  ngOnInit() {
    this.alerts$ = this.store.select(getAlerts);
    this.authStatus$ = this.store.select(getApproverAuthStatus);
    this.providerState$ = this.store.select(getFtuxProvidersState);
    this.loadingStatus$ = this.store.select(getApproverLoadingStatus);
    this.loadingStatus$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => this.loadingStatus = status);

    this.alerts$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(alerts => alerts.forEach(a => {
        if (a.providerType !== null && a.providerType !== undefined) {
          this.subscribeToInvoiceProviderLoginCompletion(a.providerType);
        }
      }))
  }

  public handleClick(provider: ApplicationTypes, index: number) {

    if (this.loadingStatus[provider] === SuccessStatus.Disabled) {
      return;
    }

    this.currentAlert = index;

    this.subscribeToApplicationLoginCompletion(provider);

    this.store.dispatch(new SetAlertFlagAction(true));

    if (provider === ApplicationTypes.Harvest) {
      this.store.dispatch(new HarvestLoginAction());
    } else if (provider === ApplicationTypes.Toggl) {
      this.store.dispatch(new TogglOpenDialogAction({
        name: ExternalInvoicingSystems[ExternalInvoicingSystems.Toggl],
        imageSrc: 'assets/toggl-nobackground.png'
      }));
    }
  }

  public subscribeToApplicationLoginCompletion(provider: ApplicationTypes) {
    const complete$ = this.store.select(getApproverComplete);
    const alertFilter = (p: ProviderState) => (alert) => alert.applicationType === provider;
    this.subscribeToLoginCompletion(complete$, alertFilter);
  }

  public subscribeToInvoiceProviderLoginCompletion(provider: ExternalInvoicingSystems) {
    const complete$ = this.store.select(getFtuxLoginComplete);
    const alertFilter =
      (p: ProviderState) =>
        (alert: Alert) =>
          alert.providerType === provider && p[provider].connectionStatus === SuccessStatus.Success;
    this.subscribeToLoginCompletion(complete$, alertFilter);
  }

  public subscribeToLoginCompletion(complete$: Observable<boolean>, alertFilter: (p: ProviderState) => (alert: Alert) => boolean) {

    const subScription = complete$
      .subscribe(complete => {
        if (complete === true) {

          this.alerts$
            .pipe(take(1), delay(1000), withLatestFrom(this.providerState$))
            .subscribe(([alerts, providerState]) =>
              alerts.filter(alertFilter(providerState))
                .forEach(alert => this.store.dispatch(new RemoveAlertAction(alert)))
            );

          this.store.dispatch(new SetAlertFlagAction(false));
        }
        subScription.unsubscribe();
      });
  }
}
